import {
  AddOutlined,
  DeleteOutlined,
  ReplayOutlined,
  SearchOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Card, CardContent, CardHeader, Grid, Link, Typography } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { useMutation } from "@tanstack/react-query"
import { Center, Flex } from "components/common"
import { AirportIataSelect } from "components/select"
import { INSURANCE_DOCS_LINK } from "env"
import { DateTime } from "luxon"
import { enqueueSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useToggle } from "react-use"
import { specialService } from "services"
import { domesticIATACodes, formatCurrency, isDomesticFlight } from "utils/common"

type Props = {
  isSearch: Boolean
  onToggleSearch?: (value: Boolean) => void
}

const FlightForm = ({ isSearch, onToggleSearch, ...props }: Props) => {
  const { control, getValues, setValue, trigger, watch } = useFormContext<InsuranceServiceDraft>()
  const { append, fields, remove } = useFieldArray({ control, name: "flightJourney" })

  const [plans, setPlans] = useState<SpecialServicingPlan[]>([])
  const [strFlightJourney, setStrFlightJourney] = useState<null | string>(null)

  const getPlansMutation = useMutation({
    mutationFn: specialService.getPlans,
    onError: () => {
      enqueueSnackbar("Có lỗi xảy ra khi lấy danh sách gói bảo hiểm", { variant: "error" })
    },
    onSuccess: (plans: SpecialServicingPlan[]) => {
      if (plans.length === 0) {
        enqueueSnackbar("Không có gói bảo hiểm nào được tìm thấy", { variant: "warning" })
      } else {
        setPlans(plans)
        onToggleSearch?.(false)
        enqueueSnackbar("Lấy danh sách gói bảo hiểm thành công", { variant: "success" })
      }
    },
  })

  const handleGetPlans = async () => {
    const data = getValues()

    const isValid = await trigger("flightJourney")
    if (!isValid) return

    if (data.flightJourney) {
      const isDomesticJourney = data.flightJourney.every((trip) =>
        isDomesticFlight(trip.airportFrom.iata, trip.airportTo.iata),
      )
      if (isDomesticJourney) {
        enqueueSnackbar("Vui lòng chọn hành trình đi quốc tế", { variant: "warning" })
        return
      }

      const isDomesticDeparture = domesticIATACodes.includes(data.flightJourney[0].airportFrom.iata)
      if (!isDomesticDeparture) {
        enqueueSnackbar("Vui lòng chọn hành trình bắt đầu từ Việt Nam", { variant: "warning" })
        return
      }

      const formattedFlightJourney = data.flightJourney.map(
        (journey): FlightJourney => ({
          ...journey,
          boardingTime: journey.boardingTime ?? "",
          from: journey.airportFrom.iata,
          to: journey.airportTo.iata,
        }),
      )

      const body: SpecialServicingListBody = {
        flightJourney: formattedFlightJourney,
        type: "INSURANCE",
      }
      const journeyStr = body.flightJourney?.map((journey) => `${journey.from}-${journey.to}`).join(" | ")
      setStrFlightJourney(journeyStr!)
      await getPlansMutation.mutateAsync({ ...body })
    }
  }

  const handleAppend = () => {
    append({
      airportFrom: {
        airportPermalink: null,
        city: null,
        cityEnName: null,
        cityName: null,
        country: null,
        countryEnName: null,
        countryLongCode: null,
        countryName: null,
        displayName: null,
        elevation: 0,
        iata: "",
        icao: "",
        id: 0,
        lat: 0,
        lon: 0,
        name: "",
        nameEN: null,
        popularity: 0,
        regionName: null,
        state: null,
        tz: null,
        worldRegionEnName: null,
        worldRegionName: null,
      },
      airportTo: {
        airportPermalink: null,
        city: null,
        cityEnName: null,
        cityName: null,
        country: null,
        countryEnName: null,
        countryLongCode: null,
        countryName: null,
        displayName: null,
        elevation: 0,
        iata: "",
        icao: "",
        id: 0,
        lat: 0,
        lon: 0,
        name: "",
        nameEN: null,
        popularity: 0,
        regionName: null,
        state: null,
        tz: null,
        worldRegionEnName: null,
        worldRegionName: null,
      },
      boardingTime: null,
    })
  }

  const handleSelectPlan = (plan: SpecialServicingPlan) => {
    setValue("coverPrice", plan.coverPrice)
    setValue("price", plan.price)
    setValue("priceCommission", plan.priceCommission)
    setValue("priceDiscountPercent", plan.priceDiscountPercent)
    setValue("totalPrice", plan.totalPrice)
    setValue("planName", plan.name)
    setValue("planDetails", plan.details)
    setValue("planId", plan.id)
    setValue("currency", plan.currency)
    setValue("coverPriceCurrency", plan.coverPriceCurrency)
  }

  const [hideDiscount, toggleHideDiscount] = useToggle(false)

  useEffect(() => {
    toggleHideDiscount(false)
  }, [isSearch, toggleHideDiscount])

  return (
    <Box>
      {isSearch &&
        fields.map((field, index) => (
          <Grid columnSpacing={1} container key={field.id} mb={2} rowSpacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={"bold"}>Hành trình {fields.length > 1 ? index + 1 : ""}:</Typography>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Controller
                control={control}
                name={`flightJourney.${index}.airportFrom`}
                render={({ field, fieldState: { error } }) => (
                  <AirportIataSelect
                    onChange={(event, value) => field.onChange(value)}
                    textFieldProps={{
                      error: !!error,
                      helperText: error?.message,
                      label: "Điểm đi",
                      required: true,
                    }}
                    value={field.value}
                  />
                )}
                rules={{
                  required: "Điểm đi không được để trống",
                }}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <Controller
                control={control}
                name={`flightJourney.${index}.airportTo`}
                render={({ field, fieldState: { error } }) => (
                  <AirportIataSelect
                    onChange={(event, value) => field.onChange(value)}
                    textFieldProps={{
                      error: !!error,
                      helperText: error?.message,
                      label: "Điểm đến",
                      required: true,
                    }}
                    value={field.value}
                  />
                )}
                rules={{
                  required: "Điểm đến không được để trống",
                }}
              />
            </Grid>

            <Grid className="flex flex-wrap items-start" gap={1} item lg={4} md={6} xs={12}>
              <Controller
                control={control}
                name={`flightJourney.${index}.boardingTime`}
                render={({ field, fieldState: { error } }) => (
                  <DateTimePicker
                    format="dd/MM/yyyy HH:mm"
                    label="Thời gian"
                    {...field}
                    showDaysOutsideCurrentMonth
                    slotProps={{ textField: { error: !!error, helperText: error?.message, required: true } }}
                  />
                )}
                rules={{
                  required: "Thời gian không được để trống",
                  validate: {
                    isValid: (value) => {
                      const selectedDate = DateTime.fromISO(value!)
                      if (!selectedDate.isValid) {
                        return "Thời gian không hợp lệ"
                      }
                      if (selectedDate < DateTime.now()) {
                        return "Thời gian phải bằng hoặc lớn hơn hiện tại"
                      }
                    },
                  },
                }}
              />

              {fields.length > 1 && (
                <Center height={56}>
                  <Button color="error" onClick={() => remove(index)} variant="contained">
                    <DeleteOutlined />
                  </Button>
                </Center>
              )}

              {index === fields.length - 1 && (
                <Button onClick={handleAppend} variant="contained">
                  <AddOutlined />
                  Thêm hành trình
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

      {isSearch && (
        <Box display="flex" gap={2} justifyContent="center" mb={2}>
          <LoadingButton
            className="px-12"
            loading={getPlansMutation.isPending}
            onClick={handleGetPlans}
            variant="contained"
          >
            <SearchOutlined />
            Tìm kiếm
          </LoadingButton>
        </Box>
      )}

      {!isSearch && plans.length > 0 && (
        <Card>
          <CardHeader
            action={
              <>
                <Button
                  className="mr-4 px-[10px]"
                  color="warning"
                  onClick={() => toggleHideDiscount(!hideDiscount)}
                  variant="outlined"
                >
                  {hideDiscount ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </Button>
                <Button
                  onClick={() => {
                    onToggleSearch?.(true)
                    setValue("coverPrice", 0)
                    setValue("price", 0)
                    setValue("planName", "")
                    setValue("planDetails", "")
                    setValue("planId", 0)
                  }}
                  variant="outlined"
                >
                  <ReplayOutlined />
                  Đổi hành trình
                </Button>
              </>
            }
            title={`Danh sách gói bảo hiểm: ${strFlightJourney}`}
          />

          <CardContent>
            <Box className="overflow-auto" display="flex" gap={2} justifyContent="safe center" mb={1} pb={2}>
              {plans.map((plan) => {
                const isSelected = plan.id === watch("planId")
                return (
                  <Card
                    className={isSelected ? "border-2 border-[green]" : "border-2"}
                    key={plan.id}
                    sx={{ minWidth: 300 }}
                  >
                    <CardContent>
                      <Box className={`flex items-center justify-between ${hideDiscount ? "mb-1" : "mb-4"}`}>
                        <Typography component="div" variant="h5">
                          Gói {plan.name}
                        </Typography>
                        <Button
                          color={isSelected ? "success" : "primary"}
                          onClick={() => handleSelectPlan(plan)}
                          size="small"
                        >
                          {isSelected ? "Đã chọn" : "Chọn gói"}
                        </Button>
                      </Box>
                      <Flex alignItems={"end"} justifyContent={"space-between"} sx={{ mb: 0.5 }}>
                        <Typography color="black" fontWeight={"bold"}>
                          Giá:
                        </Typography>
                        {hideDiscount && (
                          <Box>
                            <Typography className="text-right line-through" color="grey" variant="overline">
                              {formatCurrency(plan.price)} VND
                            </Typography>
                            <Typography color="black" fontWeight={"bold"} variant="h5">
                              {formatCurrency(plan.totalPrice)} VND
                            </Typography>
                          </Box>
                        )}

                        {!hideDiscount && (
                          <Box>
                            <Typography color="black" fontWeight={"bold"} variant="h5">
                              {formatCurrency(plan.price)} VND
                            </Typography>
                          </Box>
                        )}
                      </Flex>

                      {hideDiscount && (
                        <Flex justifyContent={"space-between"} sx={{ mb: 0.5 }}>
                          <Typography color="black" fontWeight={"bold"}>
                            Lợi nhuận:
                          </Typography>
                          <Box>
                            <Typography className="text-green text-right" variant="body2">
                              {formatCurrency(plan.priceCommission)} VND
                            </Typography>
                          </Box>
                        </Flex>
                      )}

                      <Typography variant="body1">Tai nạn cá nhân: {formatCurrency(plan.coverPrice)} USD</Typography>
                      <Typography variant="body1">Chi phí y tế: {formatCurrency(plan.coverPrice)} USD</Typography>
                      <Typography variant="body1">
                        Trợ cứu y tế:{" "}
                        <Link className="cursor-pointer" href={INSURANCE_DOCS_LINK} target="_blank">
                          Xem chi tiết
                        </Link>
                      </Typography>
                      <Typography variant="body1">
                        Hỗ trợ du lịch:{" "}
                        <Link className="cursor-pointer" href={INSURANCE_DOCS_LINK} target="_blank">
                          Xem chi tiết
                        </Link>
                      </Typography>
                      <Typography variant="body1">Dịch vụ cứu trợ IPA: Bao gồm</Typography>

                      <CardContent className="mt-2 rounded-lg bg-[#FFE3E2]">
                        <Typography color={"#EF5350"} component="div" mb={1} variant="h5">
                          Chi phí liên quan đến Covid-19
                        </Typography>
                        <Typography color={"#EF5350"}>Đến 70 tuổi</Typography>
                        <Typography mb={1} variant="body2">
                          {[1, 2].includes(plan.id)
                            ? "Theo giới hạn, quyền lợi bảo hiểm sức khoẻ quy định tại hợp đồng/Giấy chứng nhận bảo hiểm."
                            : "Tối đa $50,000/người/Thời hạn bảo hiểm, tùy số nào thấp hơn"}
                        </Typography>
                        <Typography color={"#EF5350"}>Trên 70 tuổi</Typography>
                        <Typography variant="body2">
                          {[1, 2].includes(plan.id)
                            ? "$5,000/người/thời hạn bảo hiểm tùy số nào thấp hơn."
                            : "Tối đa $50,000/người/Thời hạn bảo hiểm, tùy số nào thấp hơn"}
                        </Typography>
                      </CardContent>
                    </CardContent>
                  </Card>
                )
              })}
            </Box>
            <Center>
              <Typography color={"tomato"} fontWeight={700}>
                Lưu ý: Sản phẩm Bảo hiểm Du lịch quốc tế áp dụng cho độ tuổi từ 01 ngày tuổi đến 75 tuổi
              </Typography>
            </Center>
          </CardContent>
        </Card>
      )}

      {!isSearch && (
        <Flex className="items-center text-primary-main" ml={2} mt={2}>
          <Typography variant="h6">Đã chọn gói:</Typography>
          <Typography component="div" ml={1} variant="h5">
            {watch("planName") || "..."}
          </Typography>
        </Flex>
      )}
    </Box>
  )
}

export default FlightForm
