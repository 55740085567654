import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { tourService } from "services"

type Props = Partial<AutocompleteProps<TourCategory, false, false, false>> & {
  categoryId?: number | string
  textFieldProps?: TextFieldProps
  type?: TourCategoryType
}

const TourCategorySelect = React.forwardRef(
  ({ categoryId, textFieldProps, type, ...autocompleteProps }: Props, ref) => {
    const { data: categoryQuery } = useQuery({
      queryFn: () => tourService.getCategory({ type }),
      queryKey: ["tourService.getCategory", type],
    })
    const { items: categories = [] } = categoryQuery ?? {}

    const selectedCategory = categories.find((category) => category.id === categoryId)

    return (
      <Autocomplete<TourCategory>
        filterOptions={(options, state) =>
          options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
        }
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={categories}
        renderInput={(params) => (
          <TextField {...params} InputLabelProps={{ shrink: true }} label="Điểm đến" {...textFieldProps} />
        )}
        {...autocompleteProps}
        value={autocompleteProps.value ?? selectedCategory ?? null}
      />
    )
  },
)

export default TourCategorySelect
