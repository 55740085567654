import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, InputPassword } from "components/common"
import { AgencySelect } from "components/select"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { profileSelector } from "reducers/profileSlice"
import { queryClient, userService } from "services"
import { isValidEmail } from "utils/validator"

type DialogController = PopupController & DialogProps

type Props = DialogController

const UserDialog = ({ onClose, ...props }: Props) => {
  const { isTypeSystem, user } = useSelector(profileSelector)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm<UserCreateBody>()

  const { userType } = watch()

  const createUserMutation = useMutation({ mutationFn: userService.createUser })

  const onSubmit = async (values: UserCreateBody) => {
    const { password, username } = values
    await createUserMutation.mutateAsync({
      ...values,
      password: password ? password : undefined,
      username: username.trim().toLowerCase(),
    })
    enqueueSnackbar("Tạo mới tài khoản thành công")
    queryClient.invalidateQueries({ queryKey: ["userService.fetchUsers"] })
    onClose()
  }

  useEffect(() => {
    reset({
      agencyCode: isTypeSystem ? "" : user?.agency.code,
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      role: "",
      username: "",
      userType: isTypeSystem ? "" : user?.userType,
    })
  }, [reset, props.open, isTypeSystem, user])

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Tạo mới tài khoản</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="username"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Tên đăng nhập"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              maxLength: {
                message: "Tên đăng nhập từ 5 - 10 ký tự",
                value: 10,
              },
              minLength: {
                message: "Tên đăng nhập từ 5 - 10 ký tự",
                value: 5,
              },
              required: "Tên đăng nhập không được để trống",
            }}
          />
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Email" required {...field} error={!!error} helperText={error?.message} />
            )}
            rules={{
              required: "Email không được để trống",
              validate: {
                isValid: (value) => isValidEmail(value) || "Email không hợp lệ",
              },
            }}
          />
          <Controller
            control={control}
            name="role"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Vai trò"
                required
                select
                {...field}
                error={!!error}
                helperText={error?.message}
              >
                {["ADMIN", "USER"].map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: "Vai trò không được để trống",
            }}
          />
          <Controller
            control={control}
            name="userType"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Loại tài khoản"
                required={isTypeSystem}
                select
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ readOnly: !isTypeSystem }}
                placeholder={!isTypeSystem ? `${user?.userType}` : ""}
                sx={isTypeSystem ? {} : { display: "none" }}
              >
                {["SYSTEM", "PROVIDER", "AGENCY"].map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: "Loại tài khoản không được để trống",
            }}
          />
          <Controller
            control={control}
            name="agencyCode"
            render={({ field: { onChange, value: agencyCode } }) => {
              return (
                <AgencySelect
                  agencyCode={agencyCode}
                  onChange={(event, agency) => onChange(agency?.code)}
                  readOnly={!isTypeSystem}
                  sx={isTypeSystem && userType === "SYSTEM" ? { display: "none" } : {}}
                />
              )
            }}
          />
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <InputPassword fullWidth label="Mật khẩu" {...field} error={!!error} helperText={error?.message} />
            )}
            rules={{
              minLength: {
                message: "Mật khẩu ít nhất 6 ký tự",
                value: 6,
              },
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Họ" {...field} error={!!error} helperText={error?.message} />
            )}
          />
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Tên" {...field} error={!!error} helperText={error?.message} />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          Tạo mới
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UserDialog
