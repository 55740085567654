import { Box } from "@mui/material"
import { FlexColumn, Text } from "components/common"

const TourSearchBanner = () => {
  return (
    <Box
      className="bg-cover bg-center p-6 md:h-[420px] md:rounded-3xl"
      style={{
        backgroundImage: "url(https://airdata-cms-dev.sgp1.cdn.digitaloceanspaces.com/banner/background-tour.webp)",
      }}
    >
      <FlexColumn className="z-10 h-full items-center justify-between text-center">
        <FlexColumn className="gap-2 md:gap-6">
          <Text className="text-4xl font-bold text-white drop-shadow-md md:text-7xl">KHÁM PHÁ</Text>
          <Text className="text-5xl font-extrabold text-white/60 drop-shadow-md md:text-9xl">THẾ GIỚI</Text>
        </FlexColumn>

        <Box className="hidden rounded-lg bg-white/25 px-4 py-2 backdrop-brightness-75 md:block">
          <Text className="max-w-3xl font-semibold text-white md:text-lg">
            Dòng tour này Airdata hướng đến mục tiêu bất cứ Du Khách nào cũng có cơ hội đi du lịch với mức chi phí tiết
            kiệm nhất. Các điểm tham quan và dịch vụ chọn lọc phù hợp với ngân sách của Du Khách nhưng vẫn đảm bảo hành
            trình du lịch đầy đủ và thú vị.
          </Text>
        </Box>
      </FlexColumn>
    </Box>
  )
}

export default TourSearchBanner
