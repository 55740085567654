import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Button, Collapse, Paper, Stack } from "@mui/material"
import { Flex, Text } from "components/common"
import { useEffect, useId } from "react"
import { useToggle } from "react-use"

type Props = {
  tour: TourRow
}

const TourDetailConditions = ({ tour }: Props) => {
  const [isOpen, toggleCollapse] = useToggle(false)
  const idContainer = useId().replace(/^:/, "").replace(/:$/, "")

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById(idContainer)?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }, 300)
    }
  }, [isOpen, idContainer])

  return (
    <Paper className="p-3 shadow-md" id={idContainer} style={{ scrollMarginTop: 24 }} variant="outlined">
      <Stack>
        <Flex className="cursor-pointer items-center justify-between" onClick={toggleCollapse}>
          <Text color="primary" variant="h5">
            Lưu ý
          </Text>
          <Button variant="text">{isOpen ? <ExpandLess /> : <ExpandMore />}</Button>
        </Flex>
        <Collapse in={isOpen}>
          <Stack mt={1} spacing={4}>
            {tour.contentConditions.map((item, index) => {
              return (
                <Stack key={index} spacing={2}>
                  <Text color="secondary" textAlign="center" variant="h5">
                    {item.title}
                  </Text>
                  <Text
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    sx={{
                      "> p": { marginBottom: 0.5 },
                    }}
                  />
                </Stack>
              )
            })}
          </Stack>
        </Collapse>
      </Stack>
    </Paper>
  )
}

export default TourDetailConditions
