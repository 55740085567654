export const customerTypeOptions: Array<{ id: ApplicableCustomerType; label: string }> = [
  { id: "ADULT", label: "Người lớn" },
  { id: "CHILD_ONE", label: "Trẻ em 1" },
  { id: "CHILD_SECOND", label: "Trẻ em 2" },
  { id: "INFANT", label: "Em bé" },
]

export const tourTypeOptions: Array<{ id: PromotionTourType; label: string }> = [
  { id: "ALL", label: "TẤT CẢ" },
  { id: "DOMESTIC", label: "TRONG NƯỚC" },
  { id: "OUTBOUND", label: "QUỐC TẾ" },
]
