import { Grid, Hidden, Stack, TextField } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { InputCounter } from "components"
import { Flex, Text } from "components/common"
import { AirportLoungeSelect, AirportSelect } from "components/select"
import { DateTime } from "luxon"
import { useCallback, useEffect } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { formatCurrency } from "utils/common"
import { isValidEmail, isValidPhone } from "utils/validator"

import { AgencyForm } from "."

type Props = {
  airportLoungeFares: AirportLoungeFare[]
  airportLounges: AirportLounge[]
  airports?: Airport[]
  booking?: AirportLoungeBooking
}

const BookingForm = ({ airportLoungeFares, airportLounges, airports, booking }: Props) => {
  const { control, setValue, watch } = useFormContext<AirportLoungeBookingCreateBody>()
  const selectedAirportId = watch("airportId")

  const { fields: bookingFares, replace, update } = useFieldArray({ control, name: "bookingFares" })

  const handleReplaceBookingFares = useCallback(
    (airportLoungeId?: number, booking?: AirportLoungeBooking) => {
      const nextBookingFares = airportLoungeFares
        .filter((airportLoungeFare) => airportLoungeFare.airportLounge.id === airportLoungeId)
        .map((airportLoungeFare) => {
          const bookingFare = booking?.bookingFares.find(
            (bookingFare) => bookingFare.airportLoungeFare.id === airportLoungeFare.id,
          )
          return {
            airportLoungeFareId: airportLoungeFare.id,
            count: bookingFare?.count ?? 0,
          }
        })
      replace(nextBookingFares)
    },
    [airportLoungeFares, replace],
  )

  useEffect(() => {
    if (booking) {
      handleReplaceBookingFares(booking.airportLounge.id, booking)
    }
  }, [handleReplaceBookingFares, booking])

  return (
    <Grid columnSpacing={6} container rowSpacing={4}>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="contactName"
          render={({ field, fieldState: { error } }) => (
            <TextField fullWidth label="Họ và tên" required {...field} error={!!error} helperText={error?.message} />
          )}
          rules={{
            required: "Họ và tên không được để trống",
          }}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="contactPhone"
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="Số điện thoại"
              required
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: "Số điện thoại không được để trống",
            validate: {
              isValid: (value) => isValidPhone(value) || "Số điện thoại không hợp lệ",
            },
          }}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="contactEmail"
          render={({ field, fieldState: { error } }) => (
            <TextField fullWidth label="Email" required {...field} error={!!error} helperText={error?.message} />
          )}
          rules={{
            required: "Email không được để trống",
            validate: {
              isValid: (value) => isValidEmail(value) || "Email không hợp lệ",
            },
          }}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="servingFrom"
          render={({ field, fieldState: { error } }) => (
            <DateTimePicker
              format="dd/MM/yyyy HH:mm"
              label="Thời gian sử dụng"
              {...field}
              showDaysOutsideCurrentMonth
              slotProps={{ textField: { error: !!error, helperText: error?.message, required: true } }}
            />
          )}
          rules={{
            required: "Thời gian không được để trống",
            validate: {
              isValid: (value) => {
                const servingForm = value as unknown as DateTime
                if (!servingForm.isValid) {
                  return "Thời gian không hợp lệ"
                }
              },
            },
          }}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="airportId"
          render={({ field: { onChange, value: airportId }, fieldState: { error } }) => (
            <AirportSelect
              airportId={airportId}
              onChange={(event, airport) => {
                onChange(airport?.id)
                setValue("airportLoungeId", "")
                replace([])
              }}
              textFieldProps={{
                error: !!error,
                helperText: error?.message,
                required: true,
              }}
            />
          )}
          rules={{
            required: "Sân bay không được để trống",
          }}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="airportLoungeId"
          render={({ field: { onChange, value: airportLoungeId }, fieldState: { error } }) => {
            return (
              <AirportLoungeSelect
                airportId={selectedAirportId}
                airportLoungeId={airportLoungeId}
                onChange={(event, airportLounge) => {
                  onChange(airportLounge?.id)
                  setValue("airportId", airportLounge?.airport.id ?? "", {
                    shouldValidate: !!airportLounge?.airport.id,
                  })
                  handleReplaceBookingFares(airportLounge?.id)
                }}
                textFieldProps={{
                  error: !!error,
                  helperText: error?.message,
                  required: true,
                }}
              />
            )
          }}
          rules={{
            required: "Phòng chờ không được để trống",
          }}
        />
      </Grid>
      <Hidden lgUp>
        <Grid item lg={4} md={6} xs={12}>
          <AgencyForm />
        </Grid>
      </Hidden>
      <Grid item lg={8} md={6} xs={12}>
        <Grid columnSpacing={6} container rowSpacing={2}>
          {bookingFares.map((bookingFare, index) => {
            const airportLoungeFare = airportLoungeFares.find((item) => item.id === bookingFare.airportLoungeFareId)
            return (
              <Grid item key={bookingFare.id} xs={12}>
                <Flex className="flex-col-reverse gap-1 sm:flex-row sm:items-center sm:gap-4">
                  <InputCounter
                    onChange={(value) => update(index, { ...bookingFare, count: value })}
                    value={bookingFare.count}
                  />
                  <Stack className="flex-row gap-1 sm:flex-col sm:gap-0">
                    <Text className="font-semibold">{airportLoungeFare?.name}:</Text>
                    <Text className="font-bold text-gray-600">{formatCurrency(airportLoungeFare?.price)}</Text>
                  </Stack>
                </Flex>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Hidden lgDown>
        <Grid item lg={4} md={6} xs={12}>
          <AgencyForm />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default BookingForm
