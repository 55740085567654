import { Box } from "@mui/material"
import { P } from "models/Permissions"
import { AgencyList } from "views/Agency"
import { AgencyLoungeConfigCreate, AgencyLoungeConfigList } from "views/AgencyLoungeConfig"
import { AirportList } from "views/Airport"
import { AirportLoungeList } from "views/AirportLounge"
import { AirportLoungeBookingList, AirportLoungeBookingView } from "views/AirportLoungeBooking"
import { AirportLoungeBookingCreate } from "views/AirportLoungeBookingCreate"
import { AirportLoungeFareList } from "views/AirportLoungeFare"
import { AuditLogList } from "views/AuditLog"
import { ErrorPage } from "views/Error"
import { Profile } from "views/Profile"
import { PromotionTourCreate, PromotionTourList } from "views/PromotionTour"
import { SpecialServiceList } from "views/SpecialService"
import { SpecialServiceCreate } from "views/SpecialServiceCreate"
import { TourBookingList, TourBookingListView } from "views/TourBookingList"
import { TourCreate } from "views/TourCreate"
import { TourDetail } from "views/TourDetail"
import { TourSearch } from "views/TourSearch"
import { UserList } from "views/User"

const specialServicesRoute = {
  specialServices: {
    component: SpecialServiceList,
    name: "Dịch vụ bảo hiểm",
    path: "/special_services_list",
    requiredPermission: P.SPECIAL_SERVICE_FETCH,
  },
  specialServicesDraft: {
    component: SpecialServiceCreate,
    name: "Mua bảo hiểm",
    path: "/special_services_draft",
    requiredPermission: P.SPECIAL_SERVICE_CREATE_DRAFT,
  },
}

const airportLoungeRoute = {
  airportLoungeBookings: {
    component: AirportLoungeBookingList,
    name: "Dịch vụ phòng chờ",
    path: "/airport_lounge_bookings",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_FETCH,
  },
  airportLoungeBookingsView: {
    component: AirportLoungeBookingView,
    name: "Thông tin dịch vụ",
    path: "/airport_lounge_bookings/:id",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_READ,
    url: ({ id }: ByID) => `/airport_lounge_bookings/${id}`,
  },

  airportLoungeFormBookingsCreate: {
    component: AirportLoungeBookingCreate,
    name: "Đặt phòng chờ",
    path: "/airport_lounge_form_bookings",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_CREATE,
  },
  airportLoungeFormBookingsUpdate: {
    component: AirportLoungeBookingCreate,
    name: "Thông tin dịch vụ",
    path: "/airport_lounge_form_bookings/:id",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_UPDATE,
    url: ({ id }: ByID) => `/airport_lounge_form_bookings/${id}`,
  },
}

const toursRoute = {
  tourList: {
    component: TourBookingList,
    name: "Dach sách đặt Tour",
    path: "/tours_list",
    requiredPermission: P.TOUR_BOOKING_FETCH,
  },
  tourListView: {
    component: TourBookingListView,
    name: "Chi tiết Tour",
    path: "/tours_list/:code",
    requiredPermission: P.TOUR_BOOKING_READ,
    url: (code: number) => `/tours_list/${code}`,
  },
  tourSearch: {
    component: TourSearch,
    name: "Đặt Tour du lịch",
    path: "/tours_search",
    requiredPermission: P.TOUR_ROW_READ,
  },
  tourSearchCreate: {
    component: TourCreate,
    name: "Đặt Tour",
    path: "/tours_search/:priceId/book",
    requiredPermission: P.TOUR_BOOKING_CREATE,
    url: (priceId: string) => `/tours_search/${priceId}/book`,
  },
  tourSearchDetail: {
    component: TourDetail,
    name: "Chi tiết Tour",
    path: "/tours_search/:priceId",
    requiredPermission: P.TOUR_ROW_READ,
    url: (priceId: string) => `/tours_search/${priceId}`,
  },
}

const settingsRoute = {
  settings: {
    component: Box,
    name: "Cài đặt",
    path: "/settings",
  },
  settingsAgencies: {
    component: AgencyList,
    name: "Đại lý",
    path: "/settings/agencies",
  },
  settingsAgencyLoungeConfigs: {
    component: AgencyLoungeConfigList,
    name: "Cài đặt giá theo đại lý",
    path: "/settings/agency-lounge-configs",
  },
  settingsAgencyLoungeConfigsCreate: {
    component: AgencyLoungeConfigCreate,
    name: "Cài đặt giá theo đại lý",
    path: "/settings/agency-lounge-configs/create",
  },
  settingsAirportLoungeFare: {
    component: AirportLoungeFareList,
    name: "Loại vé",
    path: "/settings/lounge-fares",
  },
  settingsAirportLounges: {
    component: AirportLoungeList,
    name: "Phòng chờ sân bay",
    path: "/settings/lounges",
  },
  settingsAirports: {
    component: AirportList,
    name: "Sân bay",
    path: "/settings/airports",
  },
  settingsPromotionsTour: {
    component: PromotionTourList,
    name: "Promotion Du lịch",
    path: "/settings/promotions-tour",
  },
  settingsPromotionsTourCreate: {
    component: PromotionTourCreate,
    name: "Thêm mới",
    path: "/settings/promotions-tour/create",
  },
  settingsPromotionsTourEdit: {
    component: PromotionTourCreate,
    name: "Thêm mới",
    path: "/settings/promotions-tour/:id",
    url: ({ id }: ByID) => `/settings/promotions-tour/${id}`,
  },
  settingsUsers: {
    component: UserList,
    name: "Tài khoản",
    path: "/settings/users",
  },
}

const privateRoute = {
  ...specialServicesRoute,
  ...airportLoungeRoute,
  ...toursRoute,
  ...settingsRoute,
  auditLogs: {
    component: AuditLogList,
    name: "Audit Logs",
    path: "/audit-logs",
  },
  errors: {
    component: ErrorPage,
    name: "Lỗi",
    path: "/errors/:code",
  },
  home: specialServicesRoute.specialServices,
  profile: {
    component: Profile,
    name: "Profile",
    path: "/profile",
  },
}

export default privateRoute
