import { InfoOutlined } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { Flex, Text } from "components/common"
import { useSearch } from "hooks"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useToggle } from "react-use"
import { privateRoute } from "routes"
import { tourService } from "services"
import { formatDate, formatDateWithTimeZone } from "utils/common"

import { TourBookingEditDialog, TourStatusChip } from "./components"
import TourBookingListSearch from "./TourBookingListSearch"

const TourBookingList = () => {
  const { dataSearch, onSearchChange } = useSearch()
  const [isOpenEditNote, onToggleEditNote] = useToggle(false)
  const [bookingSelected, setBookingSelected] = useState<TourBooking>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => tourService.fetchBookings(dataSearch),
    queryKey: ["tourService.fetchBookings", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const handleOpenEditNote = (item: TourBooking) => {
    setBookingSelected(item)
    onToggleEditNote(true)
  }

  return (
    <Stack gap={2}>
      <TourBookingListSearch onSearchChange={onSearchChange} />
      {/* 
      <Flex className="justify-between">
        <Typography variant="h4">Danh sách đặt Tour</Typography>
      </Flex> */}

      <DataGrid
        columns={([] as GridColDef<TourBooking>[])
          .concat([
            {
              align: "center",
              field: "bookingCode",
              headerName: "Mã đặt chỗ",
              minWidth: 120,
              renderCell: ({ row: item }) => (
                <Link to={privateRoute.tourListView.url(item.bookingCode)}>
                  <Text color="primary" fontWeight="bold">
                    {item.bookingCode}
                  </Text>
                </Link>
              ),
              sortable: false,
            },
          ])
          .concat([
            {
              field: "status",
              headerName: "Trạng thái",
              minWidth: 220,
              renderCell: ({ row: item }) => {
                return <TourStatusChip booking={item} />
              },
              sortable: false,
            },
            {
              field: "tour",
              flex: 1,
              headerName: "Tour",
              minWidth: 320,
              renderCell: ({ row: item }) => (
                <Stack>
                  <Text fontWeight="bold">{item.tour.tourCode}</Text>
                  <Text className="text-sm">{item.tour.tourName}</Text>
                </Stack>
              ),
              sortable: false,
            },
            {
              field: "createdAt",
              headerName: "Thời gian đặt",
              minWidth: 180,
              renderCell: ({ row: item }) => formatDateWithTimeZone(item.createdAt),
              sortable: false,
            },
            {
              field: "createdBy",
              headerName: "Người đặt",
              minWidth: 220,
              renderCell: ({ row: item }) => {
                return (
                  <Stack>
                    <Flex>
                      <Typography fontWeight={600} variant="body1">
                        {item?.bookerInfo?.employeeCode ?? `${item.createdBy?.lastName} ${item.createdBy?.firstName}`}
                      </Typography>
                      {!item.createdBy && (
                        <Typography color="secondary" fontWeight={700} marginLeft={1}>
                          (F2)
                        </Typography>
                      )}
                    </Flex>
                    <Flex>
                      <Typography fontWeight={600} marginRight={1}>
                        Đại lý:
                      </Typography>
                      <Typography>
                        {item.bookerInfo ? item.bookerInfo.agencyCode : item.createdBy?.agency?.name}
                      </Typography>
                    </Flex>
                  </Stack>
                )
              },
              sortable: false,
            },
            {
              field: "startDate",
              headerName: "Ngày khởi hành",
              minWidth: 180,
              renderCell: ({ row: item }) => formatDate(item.startDate),
              sortable: false,
            },
            {
              align: "right",
              field: "totalPrice",
              headerName: "Giá bán",
              minWidth: 130,
              renderCell: ({ row: item }) => item.totalPrice.toLocaleString(),
              sortable: false,
            },
            {
              align: "right",
              field: "totalAmountPaid",
              headerName: "Đã đặt cọc / thanh toán",
              minWidth: 180,
              renderCell: ({ row: item }) => {
                if (item.createdBy) {
                  return (item.status === "PAID" ? item.totalPrice - item.totalCommission : 0).toLocaleString()
                }

                return (item.totalAmountPaid + item.totalPriceRefund).toLocaleString()
              },
              sortable: false,
            },
            {
              align: "right",
              field: "totalCommission",
              headerName: "Hoa hồng F1",
              minWidth: 130,
              renderCell: ({ row: item }) => item.totalCommission.toLocaleString(),
              sortable: false,
            },
            {
              align: "right",
              field: "promotion.totalAgencyPromotion",
              headerName: "Hoa hồng F2",
              minWidth: 130,
              renderCell: ({ row: item }) => item.promotion?.totalAgencyPromotion.toLocaleString(),
              sortable: false,
            },
            {
              align: "right",
              field: "priceToBePaid",
              headerName: "Số tiền cần thanh toán",
              minWidth: 180,
              renderCell: ({ row: item }) => {
                let subtractAmount = item.createdBy ? item.totalCommission : item.promotion?.totalAgencyPromotion ?? 0
                return ["CANCELLED", "EXPIRED", "PAID"].includes(item.status)
                  ? 0
                  : (item.totalPrice - item.totalAmountPaid - subtractAmount).toLocaleString()
              },
              sortable: false,
            },
            {
              align: "right",
              field: "totalPriceRefund",
              headerName: "Đã hoàn",
              minWidth: 180,
              renderCell: ({ row: item }) => item.totalPriceRefund.toLocaleString(),
              sortable: false,
            },
            {
              field: "note",
              headerName: "Ghi chú",
              minWidth: 180,
              renderCell: ({ row: item }) => item.note,
              sortable: false,
            },
            {
              align: "left",
              field: "actions",
              headerName: "",
              minWidth: 180,
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-wrap gap-2">
                    <Link to={privateRoute.tourListView.url(item.bookingCode)}>
                      <Button color="inherit" size="small">
                        <InfoOutlined />
                      </Button>
                    </Link>

                    <Button
                      className="text-nowrap"
                      color="warning"
                      onClick={() => {
                        handleOpenEditNote(item)
                      }}
                      size="small"
                    >
                      Sửa ghi chú
                    </Button>
                  </Flex>
                )
              },
              sortable: false,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
        sx={{ mt: 0 }}
      />

      <TourBookingEditDialog
        booking={bookingSelected}
        isUpdateNote={true}
        onClose={() => onToggleEditNote(false)}
        open={isOpenEditNote}
      />
    </Stack>
  )
}

export default TourBookingList
