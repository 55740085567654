import { AgencySelect } from "components/select"
import { Controller, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { profileSelector } from "reducers/profileSlice"

const AgencyForm = () => {
  const { isTypeSystem } = useSelector(profileSelector)
  const { control } = useFormContext<AirportLoungeBookingCreateBody>()

  return (
    <Controller
      control={control}
      name="agencyId"
      render={({ field: { onChange, value: agencyId }, fieldState: { error } }) => {
        return (
          <AgencySelect
            agencyId={agencyId}
            onChange={(event, agency) => onChange(agency?.id)}
            readOnly={!isTypeSystem}
            sx={isTypeSystem ? {} : { display: "none" }}
            textFieldProps={{
              error: !!error,
              helperText: error?.message,
              required: true,
            }}
          />
        )
      }}
      rules={{
        required: "Đại lý không được để trống",
      }}
    />
  )
}

export default AgencyForm
