import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { queryClient, tourService } from "services"
import { formatDuplicateTrim, formatRemoveAccents } from "utils/common"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  booking?: TourBooking
  isUpdateNote: boolean
}

const convertTourBookingToFormValues = (isUpdateNote: boolean, booking?: TourBooking) => {
  if (!booking) return isUpdateNote ? { note: "" } : { address: "", contactName: "", contactPhone: "", email: "" }

  return isUpdateNote
    ? { note: booking?.note || "" }
    : {
        address: booking?.address ?? "",
        contactName: booking?.contactName ?? "",
        contactPhone: booking?.contactPhone ?? "",
        email: booking?.email ?? "",
      }
}

const TourBookingEditDialog = ({ booking, isUpdateNote, onClose, onSuccess, ...props }: Props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TourBookingUpdateBody>({
    defaultValues: convertTourBookingToFormValues(isUpdateNote),
  })

  useEffect(() => {
    if (!booking) return
    reset(convertTourBookingToFormValues(isUpdateNote, booking))
  }, [reset, isUpdateNote, booking])

  const updateBookingMutation = useMutation({
    mutationFn: tourService.updateBooking,
    onError: (error: any) => {
      enqueueSnackbar(`Lỗi sửa thông tin: ${error?.message || "Vui lòng thử lại sau"}`, { variant: "error" })
    },
  })

  const onSubmit = async (values: TourBookingUpdateBody) => {
    const payload: TourBookingUpdateBody = {
      address: undefined,
      contactName: undefined,
      contactPhone: undefined,
      email: undefined,
      id: booking?.bookingCode!,
      note: undefined,
      //   ...Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== undefined)),
    }

    if (values.address) {
      payload.address = values.address
    }
    if (values.contactName) {
      payload.contactName = values.contactName
    }
    if (values.contactPhone) {
      payload.contactPhone = values.contactPhone
    }
    if (values.email) {
      payload.email = values.email
    }
    if (isUpdateNote) {
      payload.note = values.note || ""
    }

    await updateBookingMutation.mutateAsync({ ...payload })
    enqueueSnackbar(`Sửa thông tin tour thành công`)
    queryClient.invalidateQueries({ queryKey: ["tourService.getBooking"] })
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })

    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isUpdateNote ? "Ghi chú" : "Chỉnh sửa thông tin tour"}</DialogTitle>
      <DialogContent>
        {isUpdateNote ? (
          <Box>
            <Controller
              control={control}
              name="note"
              render={({ field, fieldState: { error } }) => (
                <TextField fullWidth label="Ghi chú" {...field} error={!!error} helperText={error?.message} />
              )}
            />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Thông tin khách hàng</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="contactName"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Tên khách hàng"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    onBlur={(event) => {
                      const formatted = formatRemoveAccents(event.target.value)
                      field.onChange(formatDuplicateTrim(formatted))
                    }}
                    onChange={(event) => {
                      const formatted = event.target.value.toUpperCase()
                      field.onChange(formatted)
                    }}
                    required
                  />
                )}
                rules={{
                  required: "Không được để trống, vui lòng nhập vào",
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="address"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Địa chỉ"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    onBlur={(event) => {
                      const formatted = formatDuplicateTrim(event.target.value)
                      field.onChange(formatted)
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="contactPhone"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Số điện thoại"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    onChange={(event) => {
                      const formatted = event.target.value.trim()
                      field.onChange(formatted)
                    }}
                    required
                  />
                )}
                rules={{
                  pattern: {
                    message: "Số điện thoại không hợp lệ",
                    value: /^(\+)?(?:[0-9] ?){6,19}[0-9]$/,
                  },
                  required: "Không được để trống, vui lòng nhập vào",
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Email"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    onChange={(event) => {
                      const formatted = event.target.value.trim()
                      field.onChange(formatted)
                    }}
                    required
                  />
                )}
                rules={{
                  pattern: {
                    message: "Email không hợp lệ",
                    value: /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
                  },
                  required: "Không được để trống, vui lòng nhập vào",
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Đóng
        </Button>
        {/* <Button color="warning" onClick={() => reset(defaultValues)}>
          Đặt lại
        </Button> */}
        <LoadingButton color="primary" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="outlined">
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TourBookingEditDialog
