import { Autocomplete, AutocompleteProps, Stack, TextField, TextFieldProps } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Text } from "components/common"
import React, { useMemo } from "react"
import { airportService } from "services"

type Props = Partial<AutocompleteProps<AirportLounge, false, false, false>> & {
  airportId?: number | string
  airportLoungeId?: number | string
  textFieldProps?: TextFieldProps
}

const AirportLoungeSelect = React.forwardRef((props: Props, ref) => {
  const { airportId, airportLoungeId, textFieldProps, ...autocompleteProps } = props

  const { data: airportQuery } = useQuery({
    queryFn: () => airportService.fetchAirports(),
    queryKey: ["airportService.fetchAirports"],
  })
  const { items: airports } = airportQuery ?? {}

  const airportLounges = useMemo(() => {
    return (airports ?? []).flatMap((airport) =>
      airport.airportLounges.map((airportLounge) => ({
        ...airportLounge,
        airport,
      })),
    )
  }, [airports])

  const selectedAirportLounge = airportLounges.find((airportLounge) => airportLounge.id === airportLoungeId)

  return (
    <Autocomplete<AirportLounge>
      filterOptions={(options, state) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={airportLounges.filter((airportLounge) => !airportId || airportLounge.airport.id === airportId)}
      renderInput={(params) => (
        <TextField {...params} InputLabelProps={{ shrink: true }} label="Phòng chờ" {...textFieldProps} />
      )}
      renderOption={(props, option) => (
        <Stack component="li" style={{ alignItems: "flex-start" }} {...props}>
          <Text>{option.name}</Text>
          <Text color="textSecondary" variant="body2">
            {option.airport.name}
          </Text>
        </Stack>
      )}
      {...autocompleteProps}
      value={autocompleteProps.value ?? selectedAirportLounge ?? null}
    />
  )
})

export default AirportLoungeSelect
