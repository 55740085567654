import {
  AccessTimeOutlined,
  CalendarMonthOutlined,
  ConfirmationNumberOutlined,
  FlightTakeoffOutlined,
  LoyaltyOutlined,
  NightShelterOutlined,
  WeekendOutlined,
} from "@mui/icons-material"
import { Button, Paper, Stack, Typography } from "@mui/material"
import { Flex, Span } from "components/common"
import { Link } from "react-router-dom"
import { privateRoute } from "routes"

type Props = {
  tour: TourRow
}

const TourDetailForm = ({ tour }: Props) => {
  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h6">Giá người lớn</Typography>
          <Typography className="text-error" variant="h4">
            {tour.adultsPriceSales.toLocaleString()} VNĐ<Span className="text-base text-gray-600"> / Khách</Span>
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Flex className="items-center">
            <ConfirmationNumberOutlined className="mr-1" color="success" />
            <Span className="mr-1">Mã:</Span>
            <Span className="font-bold text-info">{tour.tourCode}</Span>
          </Flex>
          <Flex className="items-center">
            <NightShelterOutlined className="mr-1" color="success" />
            <Span className="mr-1">Khách sạn:</Span>
            <Span className="font-bold">{tour.levelNam}</Span>
          </Flex>
          <Flex className="items-center">
            <AccessTimeOutlined className="mr-1" color="success" />
            <Span className="mr-1">Thời gian:</Span>
            <Span className="font-bold">
              {tour.totalDay}N{tour.totalNight}Đ
            </Span>
          </Flex>
          <Flex className="items-center">
            <FlightTakeoffOutlined className="mr-1" color="success" />
            <Span className="mr-1">Phương tiện:</Span>
            <Span className="font-bold">
              {tour.vehiclesGo}
              {tour.vehiclesBack && tour.vehiclesBack !== tour.vehiclesGo ? ` - ${tour.vehiclesBack}` : ""}
            </Span>
          </Flex>
          <Flex className="items-center">
            <CalendarMonthOutlined className="mr-1" color="success" />
            <Span className="mr-1">Ngày đi:</Span>
            <Span className="font-bold">{tour.startDateText}</Span>
          </Flex>
          <Flex className="items-center">
            <CalendarMonthOutlined className="mr-1" color="success" />
            <Span className="mr-1">Ngày về:</Span>
            <Span className="font-bold">{tour.endDateText}</Span>
          </Flex>
          <Flex className="items-center">
            <WeekendOutlined className="mr-1" color="success" />
            <Span className="mr-1">Số chỗ:</Span>
            <Span className="font-bold text-success">{tour.numberTicketsAvailable}</Span>
          </Flex>
          <Flex className="items-center">
            <LoyaltyOutlined className="mr-1" color="success" />
            <Span className="mr-1">Hoa hồng:</Span>
            <Span className="font-bold text-success">{tour.adultsCommission.toLocaleString()}</Span>
          </Flex>
        </Stack>

        <Link to={tour.numberTicketsAvailable ? privateRoute.tourSearchCreate.url(tour.priceId) : "#"}>
          <Button color="success" disabled={!tour.numberTicketsAvailable} fullWidth variant="contained">
            Đặt Tour
          </Button>
        </Link>
      </Stack>
    </Paper>
  )
}

export default TourDetailForm
