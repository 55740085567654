import { BoxProps, Button } from "@mui/material"
import { Flex } from "components/common"
import { useNavigate } from "react-router-dom"
import { privateRoute } from "routes"

type Props = BoxProps & {
  booking: TourBooking
  onCancelOrRefund: () => void
  onDeposit: () => void
  onEdit: () => void
  onPayment: () => void
  onReject: () => void
}

const TourBookingViewActions = ({ booking, onCancelOrRefund, onDeposit, onEdit, onPayment, onReject }: Props) => {
  const navigate = useNavigate()

  const actions = {
    BACK: (
      <Button color="inherit" key="back" onClick={() => navigate(privateRoute.tourList.path)} variant="outlined">
        Quay lại
      </Button>
    ),
    CANCEL: (
      <Button color="error" key="cancel" onClick={() => onCancelOrRefund()} variant="outlined">
        Huỷ tour
      </Button>
    ),
    CONFIRM_REFUND: (
      <Button color="error" key="confirm-refund" onClick={() => onCancelOrRefund()} variant="contained">
        Xác nhận huỷ
      </Button>
    ),
    CONFIRM_TOUR: (
      <Button key="confirm-tour" onClick={() => onPayment()} variant="outlined">
        Xác nhận tour
      </Button>
    ),
    DEPOSIT: (
      <Button color="secondary" key="deposit" onClick={() => onDeposit()} variant="contained">
        Yêu cầu đặt cọc
      </Button>
    ),
    EDIT: (
      <Button color="warning" key="edit" onClick={() => onEdit()} variant="outlined">
        Chỉnh sửa
      </Button>
    ),
    PAYMENT: (
      <Button key="PAYMENT" onClick={() => onPayment()} variant="contained">
        Yêu cầu thanh toán
      </Button>
    ),
    REJECT_REFUND: (
      <Button key="reject-cancel" onClick={() => onReject()} variant="outlined">
        Từ chối huỷ
      </Button>
    ),
  }

  const actionsF1: any = {
    CANCELLED: [actions.BACK],
    PAID: [actions.BACK, actions.CANCEL],
    RESERVED: [actions.BACK, actions.EDIT, actions.CANCEL, actions.CONFIRM_TOUR],
  }
  const actionsF2: any = {
    CANCELLATION_REQUESTED: [actions.BACK, actions.REJECT_REFUND, actions.CONFIRM_REFUND],
    CANCELLED: [actions.BACK],
    DEPOSITED: [actions.BACK, actions.EDIT, actions.CANCEL, actions.PAYMENT],
    EXPIRED: [actions.BACK],
    PAID: [actions.BACK, actions.CANCEL],
    REQUEST_PAYMENT: [actions.BACK, actions.CANCEL],
    RESERVED: [actions.BACK, actions.EDIT, actions.CANCEL, actions.DEPOSIT],
  }

  return (
    <Flex className="justify-end gap-4">
      {booking.createdBy ? actionsF1[booking.status] : actionsF2[booking.status] || []}
    </Flex>
  )
}

export default TourBookingViewActions
