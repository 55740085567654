import LocationOnIcon from "@mui/icons-material/LocationOn"
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useDebounceTextInput } from "hooks"
import React from "react"
import { cmsService } from "services"

type Props = Partial<AutocompleteProps<AirportIata, false, false, false>> & {
  textFieldProps?: TextFieldProps
}

const AirportIataSelect = React.forwardRef(({ textFieldProps, ...autocompleteProps }: Props, ref) => {
  const { debouncedValue, setValue: onInputChange } = useDebounceTextInput("")

  const { data: airportQuery, isPending } = useQuery({
    queryFn: () => cmsService.searchAirports({ searchText: debouncedValue }),
    queryKey: ["cmsService.searchAirports", debouncedValue],
  })

  const airportOptions = airportQuery?.items ?? []

  return (
    <Autocomplete<AirportIata>
      autoComplete
      autoHighlight
      filterSelectedOptions
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => (option.iata ? `${option.iata} - ${option.name}` : "")}
      includeInputInList
      isOptionEqualToValue={(option, airport) => option.id === airport.id}
      noOptionsText={isPending ? "Đang tải..." : "Không tìm thấy sân bay..."}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (!airportOptions.some((airport) => airport.iata === event.target.value)) {
          onInputChange("")
        }
      }}
      onInputChange={(event, value) => {
        onInputChange(value)
      }}
      options={airportOptions}
      ref={ref}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isPending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...textFieldProps}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Grid container gap={1}>
            <Grid className="flex items-center" item>
              <LocationOnIcon color="action" />
            </Grid>
            <Grid item xs>
              <Flex gap={0.5}>
                <Typography color="primary" fontWeight={800}>
                  {option.iata}
                </Typography>
                <Typography>{option.city || option.name}</Typography>
              </Flex>
              <Typography color="textSecondary" variant="body2">
                {option.city ? `${option.name}, ` : ""} {option.country || option.countryName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {...autocompleteProps}
      value={autocompleteProps.value ?? null}
    />
  )
})

export default AirportIataSelect
