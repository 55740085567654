import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { InputNumber } from "components"
import { DialogCloseButton } from "components/common"
import { DateTime } from "luxon"
import { enqueueSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { queryClient, tourService } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  booking: TourBooking
}

const TourBookingDepositDialog = ({ booking, onClose, onSuccess, ...props }: Props) => {
  const isSuggestPayFull = DateTime.local().plus({ days: 8 }) > DateTime.fromISO(booking.startDate, { zone: "utc" })

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TourBookingRequestPaymentBody>({
    defaultValues: {
      totalPayment: isSuggestPayFull ? booking.totalPriceToBePaid : Math.floor(booking.totalPriceToBePaid / 2),
    },
  })

  const requestPaymentBookingMutation = useMutation({ mutationFn: tourService.requestPaymentBooking })

  const onSubmit = async (values: TourBookingRequestPaymentBody) => {
    if (Number(values.totalPayment) > booking.totalPriceToBePaid) {
      enqueueSnackbar("Số tiền yêu cầu đặt cọc phải nhỏ hơn hoặc bằng giá tour", { variant: "warning" })
      return
    }

    await requestPaymentBookingMutation.mutateAsync({
      id: booking.bookingCode,
      totalPayment: Number(values.totalPayment),
    })
    enqueueSnackbar("Gửi yêu cầu đặt cọc tour thành công")
    queryClient.invalidateQueries({ queryKey: ["tourService.getBooking"] })
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Gửi yêu cầu đặt cọc Tour</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Controller
            control={control}
            name="totalPayment"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Số tiền cọc"
                {...field}
                error={!!error}
                helperText={error ? error.message : ""}
                InputProps={{ inputComponent: InputNumber }}
                required
              />
            )}
            rules={{
              required: "Số tiền cọc không được để trống",
            }}
          />

          {isSuggestPayFull && (
            <Alert icon={false} severity="warning">
              Thời gian giữ chỗ tính từ hiện tại đến ngày khởi hành thấp hơn 8 ngày, yêu cầu thanh toán toàn bộ tiền
              tour.
            </Alert>
          )}

          <Typography variant="h6">Xác nhận gửi yêu cầu đặt cọc Tour ?</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Đóng
        </Button>
        <LoadingButton color="secondary" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="outlined">
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TourBookingDepositDialog
