import { Alert, Box, CircularProgress, Grid, LinearProgress, Stack } from "@mui/material"
import { useInfiniteQuery } from "@tanstack/react-query"
import { Center, FlexColumn } from "components/common"
import { useSearch } from "hooks"
import { useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { tourService } from "services"

import { TourCardItem, TourSearchBanner, TourSearchFilter, TourSearchHeading } from "./components"

const TourSearch = () => {
  const { dataSearch, onSearchChange } = useSearch({
    sortBy: "startDate",
    sortDirection: undefined,
  })

  const { data, fetchNextPage, hasNextPage, isError, isFetching, refetch } = useInfiniteQuery({
    enabled: !!dataSearch.categoryId,
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      tourService.fetchTourRows({ ...dataSearch, page: pageParam }).then((response) => ({
        ...response,
        now: new Date().toISOString(),
      })),
    queryKey: ["tourService.fetchTourRows", dataSearch],
    ...{},
    getNextPageParam: (lastPage) => {
      if (lastPage.items.length < lastPage.size) return undefined
      return lastPage.page + 1
    },
  })

  const firstPage = data?.pages[0]
  const isLoading = firstPage?.pulling || isFetching

  useEffect(() => {
    if (firstPage?.pulling) {
      setTimeout(() => refetch(), 2000)
    }
  }, [firstPage, refetch])

  return (
    <Stack spacing={2}>
      <TourSearchBanner />

      <FlexColumn gap={2}>
        <Box position="relative">
          <TourSearchHeading onRefetch={refetch} onSearchChange={onSearchChange} tripCount={firstPage?.total} />
          {isLoading && (
            <Box bottom={-10} position="absolute" width="100%">
              <LinearProgress />
            </Box>
          )}
        </Box>

        <Grid className="flex-col-reverse md:flex-row" columnSpacing={3} container rowSpacing={2}>
          <Grid item md={9} xs={12}>
            <InfiniteScroll
              getScrollParent={() => document.getElementById("page")}
              hasMore={!!hasNextPage}
              loadMore={() => {
                if (!isLoading) fetchNextPage()
              }}
              useWindow={false}
            >
              {!firstPage?.total &&
                (isLoading ? (
                  <Alert severity="info">Tải dữ liệu tour... Vui lòng đợi trong giây lát.</Alert>
                ) : (
                  <Alert severity="warning">Không tìm thấy chương trình tour phù hợp</Alert>
                ))}
              {isError && <Alert severity="error">Lỗi tải dữ liệu, vui lòng thực hiện lại ...</Alert>}

              <Stack spacing={2}>
                {data?.pages.map((page) =>
                  page.items.map((tour, index) => {
                    return <TourCardItem key={`${tour.tourId}-${index}`} tour={tour} />
                  }),
                )}
                {isLoading && (
                  <Center padding={4}>
                    <CircularProgress />
                  </Center>
                )}
              </Stack>
            </InfiniteScroll>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className="sticky top-0">
              <TourSearchFilter onSearchChange={onSearchChange} />
            </Box>
          </Grid>
        </Grid>
      </FlexColumn>
    </Stack>
  )
}

export default TourSearch
