import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton } from "components/common"
import { AirportSelect } from "components/select"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { airportLoungeService, queryClient } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  airportLounge?: AirportLounge
}

const AirportLoungeDialog = ({ airportLounge, onClose, onSuccess, ...props }: Props) => {
  const isCreate = !airportLounge
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<AirportLoungeCreateBody>()

  useEffect(() => {
    reset({
      airportId: airportLounge?.airport.id ?? "",
      isDomestic: airportLounge?.isDomestic ?? true,
      name: airportLounge?.name ?? "",
    })
  }, [reset, props.open, airportLounge])

  const updateLoungeMutation = useMutation({ mutationFn: airportLoungeService.updateLounge })
  const createLoungeMutation = useMutation({ mutationFn: airportLoungeService.createLounge })

  const onSubmit = async (values: AirportLoungeCreateBody) => {
    if (isCreate) {
      await createLoungeMutation.mutateAsync({ ...values })
      enqueueSnackbar("Tạo mới phòng chờ thành công")
    } else {
      await updateLoungeMutation.mutateAsync({ ...values, id: airportLounge.id })
      enqueueSnackbar("Cập nhật phòng chờ thành công")
    }
    queryClient.invalidateQueries({ queryKey: ["airportLoungeService.fetchLounges"] })
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isCreate ? "Tạo phòng chờ" : "Cập nhật phòng chờ"}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="airportId"
            render={({ field: { onChange, value: airportId }, fieldState: { error } }) => (
              <AirportSelect
                airportId={airportId}
                onChange={(event, airport) => {
                  onChange(airport?.id)
                }}
                textFieldProps={{
                  error: !!error,
                  helperText: error?.message,
                  required: true,
                }}
              />
            )}
            rules={{
              required: "Sân bay không được để trống",
            }}
          />
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Tên phòng chờ"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: "Tên phòng chờ không được để trống",
            }}
          />
          <Controller
            control={control}
            name="isDomestic"
            render={({ field }) => (
              <RadioGroup
                className="flex-row gap-6 px-2.5"
                onChange={(event, value) => field.onChange(value === "true")}
                value={field.value}
              >
                <FormControlLabel control={<Radio />} label="Nội địa" value={true} />
                <FormControlLabel control={<Radio />} label="Quốc tế" value={false} />
              </RadioGroup>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          {isCreate ? "Tạo mới" : "Cập nhật"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AirportLoungeDialog
