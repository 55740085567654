import { Box, Chip, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { Flex, Text } from "components/common"
import { useSearch } from "hooks"
import { auditLogService } from "services"
import { formatDateTime } from "utils/common"

const AuditLogList = () => {
  const { dataSearch, onSearchChange } = useSearch()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => auditLogService.fetchAuditLogs(dataSearch),
    queryKey: ["auditLogService.fetchAuditLogs", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Audit Logs</Typography>
      </Flex>

      <DataGrid
        columns={[
          {
            field: "action",
            headerName: "Thao tác",
            minWidth: 320,
            renderCell: ({ row: item }) => (
              <Stack alignItems="flex-start" gap={1}>
                <Chip label={item.action} />
                <Chip label={item.feature} />
              </Stack>
            ),
            sortable: false,
          },
          {
            field: "newValue",
            flex: 1,
            headerName: "Giá trị mới",
            renderCell: ({ row: item }) =>
              item.newValue ? (
                <Box maxHeight={240} overflow="auto" width="100%">
                  <Text fontFamily="monospace" whiteSpace="pre">
                    {JSON.stringify(JSON.parse(item.newValue), null, 2)}
                  </Text>
                </Box>
              ) : (
                <Box className="text-gray-600">Empty</Box>
              ),
            sortable: false,
          },
          {
            field: "createdAt",
            headerName: "Ngày đặt",
            minWidth: 180,
            renderCell: ({ row: item }) => formatDateTime(item.createdAt),
          },
        ]}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
    </Stack>
  )
}

export default AuditLogList
