import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { queryClient, tourService } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  booking: TourBooking
}

const convertTourBookingToFormValues = (booking: TourBooking) => {
  if (!booking) return { totalPayment: 0, totalPrice: 0 }
  return {
    totalPayment:
      booking.totalPrice -
      booking.totalAmountPaid -
      (booking.createdBy ? booking.totalCommission : booking?.promotion?.totalAgencyPromotion ?? 0),
  }
}

const TourBookingPaymentDialog = ({ booking, onClose, onSuccess, ...props }: Props) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TourBookingRequestPaymentBody>({
    defaultValues: convertTourBookingToFormValues(booking),
  })

  useEffect(() => {
    if (!booking) return
    reset(convertTourBookingToFormValues(booking))
  }, [reset, booking])

  const labelAction = !!booking.createdBy ? "Xác nhận" : "Gửi yêu cầu thanh toán"

  const requestPaymentBookingMutation = useMutation({ mutationFn: tourService.requestPaymentBooking })

  const confirmBookingMutation = useMutation({ mutationFn: tourService.confirmBooking })

  const onSubmit = async (values: TourBookingRequestPaymentBody) => {
    if (!!booking.createdBy) {
      await confirmBookingMutation.mutateAsync({ id: booking.bookingCode })
    } else {
      await requestPaymentBookingMutation.mutateAsync({ id: booking.bookingCode, totalPayment: values.totalPayment })
    }

    enqueueSnackbar(`${labelAction} tour thành công`)
    queryClient.invalidateQueries({ queryKey: ["tourService.getBooking"] })
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{labelAction} Tour</DialogTitle>
      <DialogContent>
        <Stack>
          {!booking.createdBy && (
            <Flex>
              <Typography marginRight={2}>Tổng tiền còn lại cần thanh toán:</Typography>
              <Typography color="primary" fontWeight={700}>
                {(
                  booking.totalPrice -
                  booking.totalAmountPaid -
                  (booking.createdBy ? booking.totalCommission : booking?.promotion?.totalAgencyPromotion ?? 0)
                ).toLocaleString()}
              </Typography>
            </Flex>
          )}
          <Typography marginTop={3} variant="h6">
            {labelAction} Tour ?
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose} variant="outlined">
          Đóng
        </Button>
        <LoadingButton color="primary" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TourBookingPaymentDialog
