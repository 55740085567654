import { LoadingButton } from "@mui/lab"
import { Button, Card, CardContent, CardHeader, Container, Grid, MenuItem, Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Flex, SelectClearable } from "components/common"
import { TourCategorySelect } from "components/select"
import { DateTime } from "luxon"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { privateRoute } from "routes"
import { promotionTourService, queryClient } from "services"
import { PromotionForm } from "views/PromotionTour/components"

import { customerTypeOptions, tourTypeOptions } from "./utils"

const PromotionTourCreate = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isCreate = !id

  const { data: promotion } = useQuery({
    enabled: !!id,
    queryFn: () => promotionTourService.getPromotionTour({ id: id! }),
    queryKey: ["promotionTourService.getPromotionTour", id],
  })

  const form = useForm<PromotionTourCreateBody>({
    defaultValues: {
      applicableCustomerTypes: [],
      categoryId: undefined,
      endTime: null,
      name: "",
      startTime: null,
      tourType: "",
    },
    mode: "onChange",
  })

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = form

  const { startTime } = watch()

  useEffect(() => {
    reset({
      applicableCustomerTypes: customerTypeOptions.map((option) => ({
        discountAmount: 0,
        discountPercent: 0,
        type: option.id,
      })),
      categoryId: promotion?.categoryId,
      endTime: promotion?.endTime ? (DateTime.fromISO(promotion.endTime) as any) : null,
      name: promotion?.name ?? "",
      startTime: promotion?.startTime ? (DateTime.fromISO(promotion.startTime) as any) : null,
      tourType: promotion?.tourType ?? "",
    })
  }, [reset, promotion])

  const createPromotionTourMutation = useMutation({
    mutationFn: promotionTourService.createPromotionTour,
    onSuccess: () => {
      enqueueSnackbar("Tạo Promotion thành công")
    },
  })

  const updatePromotionTourMutation = useMutation({
    mutationFn: promotionTourService.updatePromotionTour,
    onSuccess: () => {
      enqueueSnackbar("Lưu Promotion thành công")
    },
  })

  const onSubmit = async (values: PromotionTourCreateBody) => {
    if (!values.tourType) values.tourType = "ALL"
    if (id) {
      await updatePromotionTourMutation.mutateAsync({ ...values, id })
    } else {
      await createPromotionTourMutation.mutateAsync(values)
    }
    queryClient.invalidateQueries({ queryKey: ["promotionTourService.fetchPromotionTours"] })
    queryClient.invalidateQueries({ queryKey: ["promotionTourService.getPromotionTour"] })
    navigate(privateRoute.settingsPromotionsTour.path)
  }
  return (
    <Container className="max-sm:px-0">
      <Stack gap={3}>
        <Card>
          <CardHeader title="Cài đặt Promotion"></CardHeader>
          <CardContent>
            <Grid columnSpacing={4} container rowSpacing={4}>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Tên chương trình"
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: "Tên không được để trống",
                  }}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="tourType"
                  render={({ field }) => (
                    <SelectClearable fullWidth label="Khu vực" onClear={() => field.onChange("")} select {...field}>
                      {tourTypeOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </SelectClearable>
                  )}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="categoryId"
                  render={({ field: { onChange, value: categoryId }, fieldState: { error } }) => (
                    <TourCategorySelect
                      categoryId={categoryId}
                      onChange={(event, airport) => {
                        onChange(airport?.id)
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Thời gian áp dụng từ"
                      {...field}
                      closeOnSelect
                      disablePast
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => setValue("startTime", null),
                        },
                        textField: { error: !!error, fullWidth: true, helperText: error?.message, required: true },
                      }}
                    />
                  )}
                  rules={{
                    required: "Thời gian áp dụng không được để trống",
                  }}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="endTime"
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Thời gian áp dụng đến"
                      {...field}
                      closeOnSelect
                      disablePast
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => setValue("endTime", null),
                        },
                        textField: { error: !!error, fullWidth: true, helperText: error?.message, required: true },
                      }}
                    />
                  )}
                  rules={{
                    required: "Thời gian áp dụng không được để trống",
                    validate: {
                      checkEndTime: (value) => {
                        if (value && startTime) {
                          const endTime = new Date(value).getTime()
                          const startTimeDate = new Date(startTime).getTime()

                          if (endTime <= startTimeDate) return "Thời gian áp dụng đến phải sau thời gian áp dụng từ"
                          else return true
                        }
                        return false
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <FormProvider {...form}>
          <PromotionForm promotion={promotion} />
        </FormProvider>

        <Flex className="justify-center gap-4 sm:gap-6">
          <Link to={privateRoute.settingsPromotionsTour.path}>
            <Button color="inherit">Quay lại</Button>
          </Link>
          <LoadingButton className="px-12" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
            {isCreate ? "Tạo Promotion" : "Lưu Promotion"}
          </LoadingButton>
        </Flex>
      </Stack>
    </Container>
  )
}

export default PromotionTourCreate
