import { Chip } from "@mui/material"

type StatusChipProps = {
  booking: TourBooking
}

const TourStatusChip = ({ booking }: StatusChipProps) => {
  let color: "default" | "error" | "info" | "primary" | "secondary" | "success" | "warning"
  let label: string

  const resolveStatus = (booking: TourBooking) => {
    if (!["REQUEST_PAYMENT"].includes(booking.status)) return booking.status
    if (booking.requestPaymentAmount + booking.totalAmountPaid === booking.totalPriceToBePaid) {
      return "REQUEST_PAYMENT"
    }
    return "REQUEST_DEPOSIT"
  }

  switch (resolveStatus(booking)) {
    case "CANCELLED":
      color = "default"
      label = "Đã huỷ"
      break
    case "PAID":
      color = "success"
      label = "Đã thanh toán"
      break
    case "EXPIRED":
      color = "default"
      label = "Đã hết hạn"
      break
    case "RESERVED":
      color = "info"
      label = "Giữ chỗ"
      break
    case "REQUEST_DEPOSIT":
      color = "warning"
      label = "Yêu cầu đặt cọc"
      break
    case "REQUEST_PAYMENT":
      color = "primary"
      label = "Yêu cầu thanh toán"
      break
    case "CANCELLATION_REQUESTED":
      color = "error"
      label = "Yêu cầu huỷ"
      break
    case "DEPOSITED":
      color = "success"
      label = " Đã đặt cọc"
      break
    default:
      color = "default"
      label = "-"
      break
  }

  return <Chip className="flex-grow uppercase" color={color} label={label} />
}

export default TourStatusChip
