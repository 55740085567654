import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { airportService } from "services"

type Props = Partial<AutocompleteProps<Airport, false, false, false>> & {
  airportId?: number | string
  textFieldProps?: TextFieldProps
}

const AirportSelect = React.forwardRef(({ airportId, textFieldProps, ...autocompleteProps }: Props, ref) => {
  const { data: airportQuery } = useQuery({
    queryFn: () => airportService.fetchAirports(),
    queryKey: ["airportService.fetchAirports"],
  })
  const { items: airports = [] } = airportQuery ?? {}

  const selectedAirport = airports.find((airport) => airport.id === airportId)

  return (
    <Autocomplete<Airport>
      filterOptions={(options, state) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={airports}
      renderInput={(params) => (
        <TextField {...params} InputLabelProps={{ shrink: true }} label="Sân bay" {...textFieldProps} />
      )}
      {...autocompleteProps}
      value={autocompleteProps.value ?? selectedAirport ?? null}
    />
  )
})

export default AirportSelect
