import { client } from "./axios"

const getPromotionTour = ({ id }: ByID): Promise<PromotionTour> => client.get(`/promotions_tour/${id}`)
const fetchPromotionTours = (params?: PrmotionTourSearchParams): Promise<PaginateResponse<PromotionTour>> =>
  client.get(`/promotions_tour`, { params })

const createPromotionTour = (body: PromotionTourCreateBody): Promise<PromotionTour> =>
  client.post(`/promotions_tour`, body)
const updatePromotionTour = ({ id, ...body }: PromotionTourUpdateBody): Promise<PromotionTour> =>
  client.patch(`/promotions_tour/${id}`, body)
const deletePromotionTour = ({ id }: ByID): Promise<null> => client.delete(`/promotions_tour/${id}`)

const promotionTourService = {
  createPromotionTour,
  deletePromotionTour,
  fetchPromotionTours,
  getPromotionTour,
  updatePromotionTour,
}

export default promotionTourService
