import { ExpandLess, ExpandMore, FileDownloadOutlined, Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { BoxProps, Button, Collapse, Grid, InputAdornment, MenuItem, Paper, Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMutation } from "@tanstack/react-query"
import { Flex, FlexColumn, SelectClearable, Text } from "components/common"
import { saveAs } from "file-saver"
import { useEffect, useId, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDebounce, useToggle } from "react-use"
import { queryClient, tourService } from "services"

type Props = BoxProps & {
  onSearchChange?: (values: TourBookingExportParams) => void
}

const TourBookingListSearch = ({ onSearchChange, ...props }: Props) => {
  const [isOpen, toggleCollapse] = useToggle(false)
  const idContainer = useId().replace(/^:/, "").replace(/:$/, "")
  const {
    control,
    formState: { defaultValues },
    reset,
    setValue,
    watch,
  } = useForm<TourBookingExportParams>({
    defaultValues: {
      bookingCode: "",
      endDate: null,
      endTripDate: null,
      startDate: null,
      startTripDate: null,
      status: "",
    },
  })

  const formValues = watch()
  const [searchParams, setSearchParams] = useState<TourBookingExportParams>({})

  useDebounce(
    () => {
      const searchParams: TourBookingExportParams = { ...formValues }

      if (searchParams.status === "") searchParams.status = undefined
      if (searchParams.bookingCode === "") searchParams.bookingCode = undefined

      if (searchParams.startDate) {
        searchParams.startDate = searchParams.startDate.toISODate()
      }
      if (searchParams.endDate) {
        searchParams.endDate = searchParams.endDate.toISODate()
      }
      if (searchParams.startTripDate) {
        searchParams.startTripDate = searchParams.startTripDate.toISODate()
      }
      if (searchParams.endTripDate) {
        searchParams.endTripDate = searchParams.endTripDate.toISODate()
      }

      setSearchParams(searchParams)
      onSearchChange?.(searchParams)
    },
    500,
    [JSON.stringify(formValues)],
  )

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById(idContainer)?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        })
      }, 200)
    }
  }, [isOpen, idContainer])

  const handleSearch = () => {
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
  }

  const exportBookingMutation = useMutation({ mutationFn: tourService.exportBooking })

  const handleExport = async () => {
    const blob = await exportBookingMutation.mutateAsync(searchParams)
    saveAs(blob, "Danh_Sach_Tour_Du_Lich.xlsx")
  }

  return (
    <Paper className="p-3 shadow" id={idContainer} variant="outlined">
      <Stack>
        <Flex className="cursor-pointer items-center justify-between" onClick={toggleCollapse}>
          <Text color="primary" variant="h5">
            Bộ lọc
          </Text>
          <Button variant="text">{isOpen ? <ExpandLess /> : <ExpandMore />}</Button>
        </Flex>

        <Collapse in={isOpen}>
          <FlexColumn className="md:flex-row" gap={3} marginTop={1}>
            <Grid columnSpacing={3} container rowSpacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="bookingCode"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      placeholder="Mã đặt chỗ"
                      size="small"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const formatted = event.target.value.replace(/\D/g, "")
                        field.onChange(formatted)
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Ngày đặt từ"
                      maxDate={formValues.endDate || undefined}
                      {...field}
                      closeOnSelect
                      slotProps={{
                        field: { clearable: true, onClear: () => setValue("startDate", null) },
                        textField: { fullWidth: true, size: "small" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Ngày đặt đến"
                      minDate={formValues.startDate || undefined}
                      {...field}
                      closeOnSelect
                      slotProps={{
                        field: { clearable: true, onClear: () => setValue("endDate", null) },
                        textField: { fullWidth: true, size: "small" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <SelectClearable
                      fullWidth
                      label="Trạng thái"
                      onClear={() => setValue("status", "")}
                      select
                      size="small"
                      {...field}
                    >
                      <MenuItem value="RESERVED">Giữ chỗ</MenuItem>
                      <MenuItem value="DEPOSITED">Đã đặt cọc</MenuItem>
                      <MenuItem value="PAID">Đã thanh toán</MenuItem>
                      <MenuItem value="CANCELLATION_REQUESTED">Yêu cầu hủy</MenuItem>
                      <MenuItem value="CANCELLED">Đã hủy</MenuItem>
                    </SelectClearable>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="startTripDate"
                  render={({ field }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Khởi hành từ ngày"
                      maxDate={formValues.endTripDate || undefined}
                      {...field}
                      closeOnSelect
                      slotProps={{
                        field: { clearable: true, onClear: () => setValue("startTripDate", null) },
                        textField: { fullWidth: true, size: "small" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="endTripDate"
                  render={({ field }) => (
                    <DatePicker
                      format="dd/MM/yyyy"
                      label="Khởi hành đến ngày"
                      {...field}
                      closeOnSelect
                      minDate={formValues.startTripDate || undefined}
                      slotProps={{
                        field: { clearable: true, onClear: () => setValue("endTripDate", null) },
                        textField: { fullWidth: true, size: "small" },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Stack gap={2} minWidth={240}>
              <LoadingButton
                color="primary"
                loading={exportBookingMutation.isPending}
                onClick={handleExport}
                startIcon={<FileDownloadOutlined />}
                variant="outlined"
              >
                Xuất Excel
              </LoadingButton>

              <Button color="warning" onClick={() => reset(defaultValues)}>
                Đặt lại
              </Button>
              <Button onClick={handleSearch}>Tìm kiếm</Button>
            </Stack>
          </FlexColumn>
        </Collapse>
      </Stack>
    </Paper>
  )
}

export default TourBookingListSearch
