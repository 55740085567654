import { Alert, Box, Divider, Paper, Stack } from "@mui/material"
import { Center, Flex, FlexColumn, Span, Text } from "components/common"

type Props = {
  tour: TourRow
}

const TourDetailFlightsAndPrices = ({ tour }: Props) => {
  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={3}>
        <Flex className="justify-center">
          <Text align="center" className="bg-yellow-100 rounded p-1" variant="h5">
            {tour.name}
          </Text>
        </Flex>

        <FlexColumn gap={1}>
          <Text align="center" color="secondary" variant="h5">
            Lịch Trình
          </Text>
          <FlexColumn className="grid-cols-2 items-start gap-x-8 gap-y-3 md:grid">
            {tour.itineraries?.map((trips, index) => {
              const isStart = index === 0
              return (
                <Stack key={index} spacing={1}>
                  {trips.map((trip, index) => {
                    return (
                      <Stack className="rounded-md bg-black/5 p-2" flex={1} key={index}>
                        <Flex className="w-full justify-between">
                          <Text className="font-bold">
                            {isStart ? "Ngày đi" : "Ngày về"}: <Span className="text-info">{trip.departureDate}</Span>
                          </Text>
                          <Text className="font-bold text-error">
                            {trip.airline}
                            {trip.flightNumber}
                          </Text>
                        </Flex>
                        <Flex className="w-full">
                          <Stack alignItems="center">
                            <Text className="font-bold">{trip.departureTime}</Text>
                            <Text className="font-bold">{trip.departure}</Text>
                          </Stack>
                          <Box className="px-3 py-3" flex={1}>
                            <Divider className="border-b-2" />
                          </Box>
                          <Stack alignItems="center">
                            <Text className="font-bold">{trip.arrivalTime}</Text>
                            <Text className="font-bold">{trip.destination}</Text>
                          </Stack>
                        </Flex>
                      </Stack>
                    )
                  })}
                </Stack>
              )
            })}
          </FlexColumn>
        </FlexColumn>

        <FlexColumn gap={1}>
          <Text align="center" color="secondary" variant="h5">
            Giá Tour
          </Text>
          <Box className="rounded-md bg-black/5 p-2">
            <FlexColumn className="grid-cols-2 gap-x-8 gap-y-3 md:grid">
              <Flex className="w-full flex-col justify-between md:flex-row">
                <Stack>
                  <Text className="font-bold">Người lớn</Text>
                  <Text className="text-sm font-bold text-gray-600">{`(Từ ${tour.agesAdults + 1} tuổi trở lên)`}</Text>
                </Stack>
                <Stack alignItems="flex-end">
                  <Text className="text-xl font-bold text-error">{tour.adultsPriceSales.toLocaleString()}</Text>
                  <Text className="text-sm italic">
                    Hoa hồng: <Span className="font-bold text-success">{tour.adultsCommission.toLocaleString()}</Span>
                  </Text>
                </Stack>
              </Flex>
              <Flex className="w-full flex-col justify-between md:flex-row">
                <Stack>
                  <Text className="font-bold">Trẻ em 2 (ngủ ghép)</Text>
                  <Text className="text-sm font-bold text-gray-600">{`(Từ ${tour.agesChildren2From}-${tour.agesChildren2To} tuổi)`}</Text>
                </Stack>
                <Stack alignItems="flex-end">
                  <Text className="text-xl font-bold text-error">
                    {tour.agesChildren2From > 0 ? tour.childrenNoBedPriceSales.toLocaleString() : "-"}
                  </Text>
                  {tour.agesChildren2From > 0 && (
                    <Text className="text-sm italic">
                      Hoa hồng:{" "}
                      <Span className="font-bold text-success">{tour.childrenNoBedCommission.toLocaleString()}</Span>
                    </Text>
                  )}
                </Stack>
              </Flex>
              <Flex className="w-full flex-col justify-between md:flex-row">
                <Stack>
                  <Text className="font-bold">Trẻ em 1</Text>
                  <Text className="text-sm font-bold text-gray-600">{`(Từ ${tour.agesChildren1From}-${tour.agesChildren1To} tuổi)`}</Text>
                </Stack>

                <Stack alignItems="flex-end">
                  <Text className="text-xl font-bold text-error">
                    {tour.agesChildren1From > 0 ? tour.childrenHasBedPriceSales.toLocaleString() : "-"}
                  </Text>
                  {tour.agesChildren1From > 0 && (
                    <Text className="text-sm italic">
                      Hoa hồng:{" "}
                      <Span className="font-bold text-success">{tour.childrenHasBedCommission.toLocaleString()}</Span>
                    </Text>
                  )}
                </Stack>
              </Flex>
              <Flex className="w-full flex-col justify-between md:flex-row">
                <Stack>
                  <Text className="font-bold">Em bé</Text>
                  <Text className="text-sm font-bold text-gray-600">{`(Dưới ${tour.agesInfant} tuổi)`}</Text>
                </Stack>
                <Stack alignItems="flex-end">
                  <Text className="text-xl font-bold text-error">{tour.infantPriceSales.toLocaleString()}</Text>
                  <Text className="text-sm italic">
                    Hoa hồng: <Span className="font-bold text-success">{tour.infantCommission.toLocaleString()}</Span>
                  </Text>
                </Stack>
              </Flex>
            </FlexColumn>
          </Box>
          <Center>
            <Alert icon={false} severity="success">
              Chưa bao gồm tiền phí dịch vụ 100.000 vnđ/ ngày/ khách.
            </Alert>
          </Center>
        </FlexColumn>
      </Stack>
    </Paper>
  )
}

export default TourDetailFlightsAndPrices
