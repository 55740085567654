import ClearIcon from "@mui/icons-material/Clear"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { Autocomplete, Box, Button, FormControl, FormLabel, Paper, Stack, TextField, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDebounce } from "react-use"
import { tourService } from "services"

type Props = {
  onSearchChange?: (values: TourRowParams) => void
}

const TourSearchFilter = ({ onSearchChange }: Props) => {
  const {
    control,
    formState: { defaultValues },
    reset,
    setValue,
    watch,
  } = useForm<TourRowParams>({
    defaultValues: {
      categoryId: "",
      maxPrice: null,
      minPrice: null,
      month: "",
      type: "OUTBOUND",
    },
  })
  const formValues = watch()

  const [searchParams, setSearchParams] = useState<TourRowParams>({})

  useDebounce(
    () => {
      const searchParams: TourRowParams = { ...formValues }
      if (searchParams.categoryId === "") {
        searchParams.categoryId = undefined
      }
      if (searchParams.month === "") {
        searchParams.month = undefined
      }
      setSearchParams(searchParams)
      onSearchChange?.(searchParams)
    },
    300,
    [JSON.stringify(formValues)],
  )

  const { data: categoryQuery } = useQuery({
    queryFn: () => tourService.getCategory({ type: searchParams.type }),
    queryKey: ["tourService.getCategory", searchParams.type],
  })

  const categoryOptions = useMemo(() => {
    return (categoryQuery?.items ?? []).map((category) => ({ ...category, label: category.name }))
  }, [categoryQuery])

  const handleResetSearch = () => {
    reset({
      ...defaultValues,
      categoryId: categoryOptions[0]?.id,
    })
  }

  useEffect(() => {
    const categoryId = categoryOptions[0]?.id
    if (categoryId) {
      setValue("categoryId", categoryId)
    }
  }, [setValue, categoryOptions])

  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={2}>
        <Box className="flex items-center justify-between">
          <Box className="flex items-center gap-1">
            <FilterAltIcon className="text-gray-600" />
            <Typography className="text-lg font-bold">Bộ lọc</Typography>
          </Box>
          <Button color="error" onClick={handleResetSearch} size="small" startIcon={<ClearIcon />} variant="outlined">
            Xóa lọc
          </Button>
        </Box>

        <Stack spacing={3}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <FormLabel>Dòng Tour</FormLabel>
                  <Box className="grid grid-cols-2 gap-2">
                    <Button
                      onClick={() => field.onChange("OUTBOUND")}
                      variant={field.value === "OUTBOUND" ? "contained" : "outlined"}
                    >
                      Quốc tế
                    </Button>
                    <Button
                      onClick={() => field.onChange("DOMESTIC")}
                      variant={field.value === "DOMESTIC" ? "contained" : "outlined"}
                    >
                      Trong nước
                    </Button>
                  </Box>
                </FormControl>
              )
            }}
          />

          <Controller
            control={control}
            name="categoryId"
            render={({ field }) => {
              const selectedOption = categoryOptions.find((option) => option.id === field.value)
              return (
                <FormControl>
                  <FormLabel>Điểm đến</FormLabel>
                  <Autocomplete
                    disableClearable
                    onChange={(event, option) => field.onChange(option?.id)}
                    options={categoryOptions}
                    renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} size="small" />}
                    value={selectedOption ?? null!}
                  />
                </FormControl>
              )
            }}
          />

          <Controller
            control={control}
            name="month"
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Thời gian</FormLabel>
                  <Box className="grid grid-cols-6 gap-1">
                    {Array.from({ length: 12 })
                      .map((_, i) => ({ label: `T${i + 1}`, value: i + 1 }))
                      .map((item) => {
                        const isSelected = item.value === field.value
                        return (
                          <Button
                            key={item.value}
                            onClick={() => {
                              if (isSelected) {
                                field.onChange(null)
                              } else {
                                field.onChange(item.value)
                              }
                            }}
                            variant={isSelected ? "contained" : "outlined"}
                          >
                            {item.label}
                          </Button>
                        )
                      })}
                  </Box>
                </FormControl>
              )
            }}
          />

          <Controller
            control={control}
            name="minPrice"
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Ngân sách</FormLabel>
                  <Box className="grid grid-cols-2 gap-1">
                    {[
                      { label: "Dưới 5 triệu", prices: [0, 5e6], value: "1" },
                      { label: "Từ 5 - 10 triệu", prices: [5e6, 10e6], value: "2" },
                      { label: "Từ 10 - 20 triệu", prices: [10e6, 20e6], value: "3" },
                      { label: "Trên 20 triệu", prices: [20e6, null], value: "4" },
                    ].map((item) => {
                      const [minPrice, maxPrice] = item.prices
                      const isSelected = minPrice === field.value
                      return (
                        <Button
                          onClick={() => {
                            if (isSelected) {
                              field.onChange(null)
                              setValue("maxPrice", null)
                            } else {
                              field.onChange(minPrice)
                              setValue("maxPrice", maxPrice)
                            }
                          }}
                          variant={isSelected ? "contained" : "outlined"}
                        >
                          {item.label}
                        </Button>
                      )
                    })}
                  </Box>
                </FormControl>
              )
            }}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default TourSearchFilter
