import { CloudDownloadOutlined } from "@mui/icons-material"
import { Box, Button, Link, Popover, Stack } from "@mui/material"
import { MouseEvent, useState } from "react"

type Props = {
  fileUpload: FileUploadDto[]
}

const TourDetailDownloadFile = ({ fileUpload }: Props) => {
  const [downloadEl, setDownloadEl] = useState<HTMLButtonElement | null>(null)
  const downloadFilehandle = (event: MouseEvent<HTMLButtonElement>) => {
    setDownloadEl(event.currentTarget)
  }
  const closeDownloadHandle = () => {
    setDownloadEl(null)
  }
  const open = Boolean(downloadEl)
  const id = open ? "download-file-popover" : undefined

  return (
    <Box>
      <Button
        aria-describedby={id}
        color="success"
        onClick={downloadFilehandle}
        startIcon={<CloudDownloadOutlined />}
        variant="contained"
      >
        Tải thông tin về
      </Button>
      <Popover
        anchorEl={downloadEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        id={id}
        onClose={closeDownloadHandle}
        open={open}
      >
        <Stack className="items-start px-3 py-2" spacing={1}>
          {fileUpload.map((file: FileUploadDto, index: number) => (
            <Link href={file.pathExt} key={file.id} underline="hover">
              {index ? `File ${index}: ` : ""} {file.name}
            </Link>
          ))}
        </Stack>
      </Popover>
    </Box>
  )
}

export default TourDetailDownloadFile
