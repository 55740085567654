import { Chip } from "@mui/material"

type StatusChipProps = {
  status: ServiceStatus
}

const InsuranceStatusChip = ({ status }: StatusChipProps) => {
  let color: "default" | "error" | "info" | "success" | "warning"
  let label: string

  switch (status) {
    case "CANCEL":
      color = "error"
      label = "Đã huỷ"
      break
    case "PAID":
      color = "success"
      label = "Đã thanh toán"
      break
    case "PENDING_PAYMENT":
      color = "info"
      label = "Chưa thanh toán"
      break
    case "PENDING_SUBMISSION":
      color = "default"
      label = "Chờ xác nhận"
      break
    case "REFUND":
      color = "warning"
      label = "Đã hoàn"
      break
    default:
      color = "default"
      label = "-"
      break
  }

  return <Chip color={color} label={label} />
}

export default InsuranceStatusChip
