import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import PeopleIcon from "@mui/icons-material/People"
import PlaceIcon from "@mui/icons-material/Place"
import RestaurantIcon from "@mui/icons-material/Restaurant"
import { Paper, Stack } from "@mui/material"
import { Center, Flex, FlexColumn, Text } from "components/common"

const tripDetails = [
  {
    description: "Phuket, Phi Phi",
    icon: <PlaceIcon className="text-info" />,
    title: "Điểm tham quan",
  },
  {
    description: "Buffet sáng, Theo thực đơn, Đặc sản địa phương",
    icon: <RestaurantIcon className="text-info" />,
    title: "Ẩm thực",
  },
  {
    description: "Cặp đôi, Gia đình nhiều thế hệ, Thanh niên",
    icon: <PeopleIcon className="text-info" />,
    title: "Đối tượng thích hợp",
  },
  {
    description: "Quanh Năm",
    icon: <AccessTimeIcon className="text-info" />,
    title: "Thời gian lý tưởng",
  },
  {
    description: "Máy bay, Xe du lịch",
    icon: <DirectionsBusIcon className="text-info" />,
    title: "Phương tiện",
  },
  {
    description: "Đã ưu đãi trực tiếp vào giá tour",
    icon: <LocalOfferIcon className="text-info" />,
    title: "Ưu đãi",
  },
]

const TourDetailAdditionalTripInfo = () => {
  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={2}>
        <Text variant="h5">THÔNG TIN THÊM VỀ CHUYẾN ĐI</Text>

        <FlexColumn className="grid-cols-3 gap-2 md:grid">
          {tripDetails.map((detail, index) => (
            <Flex gap={1} key={index}>
              <Center height={40}>{detail.icon}</Center>
              <Stack>
                <Text className="font-bold">{detail.title}</Text>
                <Text className="text-sm text-gray-600">{detail.description}</Text>
              </Stack>
            </Flex>
          ))}
        </FlexColumn>
      </Stack>
    </Paper>
  )
}

export default TourDetailAdditionalTripInfo
