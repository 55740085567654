import { Box, CircularProgress, Container, Grid, Stack } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Center, FlexColumn } from "components/common"
import { useParams } from "react-router-dom"
import { tourService } from "services"

import {
  TourDetailCalendar,
  TourDetailConditions,
  TourDetailConfirm,
  TourDetailContent,
  TourDetailDownloadFile,
  TourDetailFlightsAndPrices,
  TourDetailImageGallery,
  TourDetailRelated,
} from "./components"

const TourDetail = () => {
  const { priceId } = useParams()

  const { data: tour } = useQuery({
    queryFn: () => tourService.getTourRow({ id: priceId! }),
    queryKey: ["tourService.getTourRow", priceId],
  })

  if (!tour)
    return (
      <Center padding={10}>
        <CircularProgress />
      </Center>
    )
  return (
    <FlexColumn gap={3}>
      <TourDetailImageGallery images={[tour?.block?.image?.path]} />

      <Container className="max-sm:px-0" maxWidth="xl">
        <Grid columnSpacing={3} container rowSpacing={2}>
          <Grid item md={9} xs={12}>
            <Stack spacing={3}>
              <TourDetailFlightsAndPrices tour={tour} />
              {tour?.fileUpload?.length && <TourDetailDownloadFile fileUpload={tour.fileUpload} />}
              <TourDetailCalendar tour={tour} />
              <TourDetailContent tour={tour} />
              <TourDetailConditions tour={tour} />
              <TourDetailRelated tour={tour} />
            </Stack>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className="sticky top-0">
              <TourDetailConfirm tour={tour} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </FlexColumn>
  )
}

export default TourDetail
