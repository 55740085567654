import { Grid, TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { isValidEmail } from "utils/validator"

const AgencyEmailForm = () => {
  const { control } = useFormContext<InsuranceServiceDraft>()

  return (
    <Grid columnSpacing={4} container rowSpacing={4}>
      <Grid item md={8} xs={10}>
        <Controller
          control={control}
          name="agencyEmail"
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="Email"
              required
              {...field}
              error={!!error}
              helperText={error?.message}
              onBlur={(event) => {
                const trimmed = event.target.value.trim()
                field.onChange(trimmed)
              }}
            />
          )}
          rules={{
            required: "Email không được để trống",
            validate: {
              isValid: (value) => isValidEmail(value) || "Email không hợp lệ",
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AgencyEmailForm
