import { client } from "./axios"

const getCategory = (params?: CategoryParams): Promise<PaginateResponse<TourCategory>> =>
  client.get(`/tours-portal/category`, { params })
const fetchTourRows = (params?: TourRowParams): Promise<PaginateResponse<TourRow>> =>
  client.get(`/tours-portal/row`, { params })
const getTourRow = ({ id }: ByID): Promise<TourRow> => client.get(`/tours-portal/row/${id}`)
const getTourCalendar = ({ id }: ByID): Promise<Array<TourCalendar>> =>
  client.get(`/tours-portal/calendar/list/${id}`, { params: { size: 1e3 } })

const getBooking = ({ id }: ByID): Promise<TourBooking> => client.get(`/tours-portal/booking/${id}`)
const fetchBookings = (params?: PaginateParams): Promise<PaginateResponse<TourBooking>> =>
  client.get(`/tours-portal/booking`, { params })

const createBooking = (body: TourBookingCreateBody): Promise<TourBookingCreateData> =>
  client.post(`/tours-portal/booking`, body)
const updateBooking = ({ id, ...body }: TourBookingUpdateBody): Promise<TourBooking> =>
  client.patch(`/tours-portal/booking/${id}`, body)

const requestPaymentBooking = ({ id, ...body }: TourBookingRequestPaymentBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/request_payment`, body)
const cancelBooking = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/cancel`, body)
const rejectBooking = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/reject`, body)
const refundBooking = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/refund`, body)
const confirmBooking = ({ id }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/sure`)
const exportBooking = (body: TourBookingExportParams): Promise<Blob> =>
  client.post(`/tours-portal/booking/export`, body, { responseType: "blob" })

const tourService = {
  cancelBooking, // Huỷ tour.
  confirmBooking, // Xác nhận tour F1.
  createBooking,
  exportBooking,
  fetchBookings,
  fetchTourRows,
  getBooking,
  getCategory,
  getTourCalendar,
  getTourRow,
  refundBooking, // Hoàn tour F2.
  rejectBooking, // Từ chối "yêu cầu huỷ tour" của F2.
  requestPaymentBooking, // Gửi "yêu cầu thanh toán" cho F2.
  updateBooking, // Update thông tin hành khách, note của tour.
}

export default tourService
