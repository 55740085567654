import { Paper, Stack } from "@mui/material"
import { Flex, FlexColumn, Span, Text } from "components/common"
import { Link } from "react-router-dom"
import { privateRoute } from "routes"

type Props = {
  tour: TourRow
}

const TourDetailRelated = ({ tour }: Props) => {
  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={1}>
        <Flex className="min-h-10 items-center">
          <Text color="primary" variant="h5">
            Các Tour Du lịch khác
          </Text>
        </Flex>

        <FlexColumn className="grid-cols-3 gap-3 md:grid">
          {tour.anotherTours.map((tour) => {
            return (
              <FlexColumn
                className="h-[320px] justify-end rounded-lg p-3"
                key={tour.priceId}
                style={{ backgroundImage: `url(${tour.image.path})` }}
              >
                <Stack className="rounded-md bg-white/75 p-3" spacing={1}>
                  <Text variant="h5">{tour.name}</Text>
                  <Text className="font-bold">{tour.tourCode}</Text>
                  <Flex className="justify-between">
                    <Text className="font-bold">
                      Giá: <Span className="text-xl text-error">{tour.adultsPriceSales.toLocaleString()}</Span>
                    </Text>
                    <Link to={privateRoute.tourSearchDetail.url(tour.priceId)}>
                      <Text className="font-bold" color="primary">
                        Chi tiết
                      </Text>
                    </Link>
                  </Flex>
                </Stack>
              </FlexColumn>
            )
          })}
        </FlexColumn>
      </Stack>
    </Paper>
  )
}

export default TourDetailRelated
