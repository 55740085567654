import { CircularProgress, Paper, Stack } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Center, Flex, FlexColumn, Span, Text } from "components/common"
import { Link } from "react-router-dom"
import { privateRoute } from "routes"
import { tourService } from "services"
import { formatDate } from "utils/common"

type Props = {
  tour: TourRow
}

const TourDetailCalendar = ({ tour }: Props) => {
  const { data: calendars, isLoading } = useQuery({
    queryFn: () => tourService.getTourCalendar({ id: tour.tourId }),
    queryKey: ["tourService.getTourCalendar", tour.tourId],
  })

  const relatedCount = calendars?.map((calendar) => calendar.prices).flatMap((price) => price).length

  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Stack spacing={1}>
        <Flex className="min-h-10 items-center">
          <Text color="primary" variant="h5">
            Lịch khởi hành
          </Text>
        </Flex>

        {!!relatedCount && (
          <FlexColumn className="grid-cols-3 gap-3 md:grid" maxHeight={400} overflow="auto">
            {calendars?.map((calendar) => {
              return calendar.prices
                .filter((price) => price.id !== tour.priceId)
                .map((price) => {
                  return (
                    <Link key={price.id} to={privateRoute.tourSearchDetail.url(price.id)}>
                      <Paper className="p-3 hover:bg-black/5" variant="outlined">
                        <Text>
                          Ngày đi: <Span className="font-bold text-info">{formatDate(calendar.startDate)}</Span>
                        </Text>
                        <Text>
                          Số chỗ: <Span className="font-bold text-success">{calendar.numberSeatAvailable}</Span>
                        </Text>
                        <Text>
                          Giá: <Span className="font-bold text-error">{price.adultsPrice.toLocaleString()}</Span>
                        </Text>
                        <Text>
                          Hoa hồng:{" "}
                          <Span className="font-bold text-success">{price.adultsCommission.toLocaleString()}</Span>
                        </Text>
                      </Paper>
                    </Link>
                  )
                })
            })}
          </FlexColumn>
        )}
        {isLoading && (
          <Center pb={2}>
            <CircularProgress />
          </Center>
        )}
      </Stack>
    </Paper>
  )
}

export default TourDetailCalendar
