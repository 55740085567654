import { Autocomplete, AutocompleteProps, Stack, TextField, TextFieldProps } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Text } from "components/common"
import React from "react"
import { agencyService } from "services"

type Props = Partial<AutocompleteProps<Agency, false, false, false>> & {
  agencyCode?: number | string
  agencyId?: number | string
  textFieldProps?: TextFieldProps
}

const AgencySelect = React.forwardRef(({ agencyCode, agencyId, textFieldProps, ...autocompleteProps }: Props, ref) => {
  const { data: agencyQuery } = useQuery({
    queryFn: () => agencyService.fetchAgencies(),
    queryKey: ["agencyService.fetchAgencies"],
  })
  const { items: agencies = [] } = agencyQuery ?? {}

  const selectedAgency =
    agencies.find((agency) => agency.id === agencyId) ?? agencies.find((agency) => agency.code === agencyCode)

  return (
    <Autocomplete<Agency>
      filterOptions={(options, state) =>
        options.filter(
          (option) =>
            option.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            option.code.toLowerCase().includes(state.inputValue.toLowerCase()),
        )
      }
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={agencies}
      renderInput={(params) => (
        <TextField {...params} InputLabelProps={{ shrink: true }} label="Đại lý" {...textFieldProps} />
      )}
      renderOption={(props, option) => (
        <Stack component="li" style={{ alignItems: "flex-start" }} {...props}>
          <Text>{option.name}</Text>
          <Text color="textSecondary" variant="body2">
            {option.code}
          </Text>
        </Stack>
      )}
      {...autocompleteProps}
      value={autocompleteProps.value ?? selectedAgency ?? null}
    />
  )
})

export default AgencySelect
