import { Card, CardContent, CardHeader, FormControl, InputAdornment, TextField } from "@mui/material"
import { InputNumber } from "components"
import { FlexColumn } from "components/common"
import { useEffect, useState } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { customerTypeOptions } from "../utils"

type Props = {
  promotion?: PromotionTour
}

const PromotionForm = ({ promotion }: Props) => {
  const { control } = useFormContext<PromotionTourCreateBody>()
  const { fields: promotions, update } = useFieldArray({ control, name: "applicableCustomerTypes" })

  useEffect(() => {
    setTimeout(() => {
      promotion?.applicableCustomerTypes?.forEach((customer, index) => {
        update(index, customer)
      })
    }, 0)
  }, [update, promotion])

  const [isDiscountVND] = useState(true)

  return (
    <Card>
      <CardHeader title="Đối tượng áp dụng" />
      <CardContent>
        <FlexColumn className="grid grid-cols-2 gap-8">
          {promotions.map((promotion, index) => {
            return (
              <Controller
                control={control}
                key={promotion.id}
                name={`applicableCustomerTypes.${index}.discountAmount`}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <TextField
                      fullWidth
                      label={customerTypeOptions.find((option) => option.id === promotion.type)?.label}
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{isDiscountVND ? "VND" : "%"}</InputAdornment>,
                        inputComponent: InputNumber,
                      }}
                    />
                  </FormControl>
                )}
                rules={{
                  required: "Giảm giá không được để trống",
                }}
              />
            )
          })}
        </FlexColumn>
      </CardContent>
    </Card>
  )
}

export default PromotionForm
