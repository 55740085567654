import { Box } from "@mui/material"

type Props = {
  images: string[]
}

const TourDetailImageGallery = ({ images }: Props) => {
  return (
    <Box className="grid grid-cols-12 gap-2 md:h-[420px] md:gap-4">
      <Box
        alt="Large Image"
        className="col-span-5 row-span-2 h-full w-full rounded-lg object-cover"
        component="img"
        src={images[0] || "https://via.placeholder.com/800x600"}
      />
      <Box
        alt="Image 1"
        className="col-span-4 h-full w-full rounded-lg object-cover"
        component="img"
        src={images[0] || "https://via.placeholder.com/800x600"}
      />
      <Box
        alt="Image 2"
        className="col-span-3 h-full w-full rounded-lg object-cover"
        component="img"
        src={images[0] || "https://via.placeholder.com/800x600"}
      />
      <Box
        alt="Image 3"
        className="col-span-3 h-full w-full rounded-lg object-cover"
        component="img"
        src={images[0] || "https://via.placeholder.com/800x600"}
      />
      <Box
        alt="Image 4"
        className="col-span-4 h-full w-full rounded-lg object-cover"
        component="img"
        src={images[0] || "https://via.placeholder.com/800x600"}
      />
    </Box>
  )
}

export default TourDetailImageGallery
