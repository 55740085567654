import { cmsClient as client } from "./axios"

const searchAirports = (params?: SearchAirportsParams): Promise<SearchAirportsRes> =>
  client.get(`/public/airports-iata`, { params })

const cmsService = {
  searchAirports,
}

export default cmsService
