import { BoxProps, Grid } from "@mui/material"
import { Flex } from "components/common"
import { AgencySelect } from "components/select"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useDebounce } from "react-use"
import { profileSelector } from "reducers/profileSlice"

type FormValues = {
  agencyId: number | string | undefined
  searchText: string | undefined
}

type Props = BoxProps & {
  onSearchChange?: (values: FormValues) => void
}

const AgencyLoungeConfigSearch = ({ onSearchChange, ...props }: Props) => {
  const { control, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      agencyId: "",
      searchText: "",
    },
  })
  const { agencyId } = watch()
  const { searchText } = watch()
  const { isTypeAgency, user } = useSelector(profileSelector)

  useDebounce(() => onSearchChange?.({ agencyId: agencyId || undefined, searchText: searchText || undefined }), 100, [
    searchText,
    agencyId,
  ])

  useEffect(() => {
    if (isTypeAgency) {
      setValue("agencyId", user?.agency.id)
    }
  }, [user, setValue, isTypeAgency])

  return (
    <Flex gap={6} {...props}>
      <Grid columnSpacing={4} container rowSpacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="agencyId"
            render={({ field: { onChange, value: agencyId } }) => {
              return (
                <AgencySelect
                  agencyId={agencyId}
                  onChange={(event, agency) => onChange(agency?.id)}
                  textFieldProps={{ size: "small" }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </Flex>
  )
}

export default AgencyLoungeConfigSearch
