import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Center, DialogCloseButton, Flex } from "components/common"
import { INSURANCE_DOCS_LINK } from "env"
import { enqueueSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useToggle } from "react-use"
import { queryClient, specialService } from "services"
import { formatCurrency } from "utils/common"

type DialogController = PopupController & DialogProps
type Props = DialogController & {
  InsuranceItem?: SpecialService
}

const CreateCoverNoteDialog = ({ InsuranceItem, onClose, onSuccess, ...props }: Props) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<InsuranceDraftEditBody>()

  const [plans, setPlans] = useState<SpecialServicingPlan[]>([])

  const getPlansMutation = useMutation({
    mutationFn: specialService.getPlans,
    onError: () => {
      enqueueSnackbar("Có lỗi xảy ra khi lấy danh sách gói bảo hiểm", { variant: "error" })
    },
    onSuccess: (plans: SpecialServicingPlan[]) => {
      if (plans.length === 0) {
        enqueueSnackbar("Không có gói bảo hiểm nào được tìm thấy", { variant: "warning" })
      } else {
        setPlans(plans)
        enqueueSnackbar("Lấy danh sách gói bảo hiểm thành công", { variant: "success" })
      }
    },
  })

  useEffect(() => {
    if (props.open) {
      const data: SpecialServicingListBody = {
        flightJourney: InsuranceItem?.flightJourney,
        type: "INSURANCE",
      }
      getPlansMutation.mutateAsync({ ...data })
    } else {
      setPlans([])
      reset({
        coverPrice: 0,
        coverPriceCurrency: "",
        currency: "",
        id: 0,
        planDetails: "",
        planId: 0,
        planName: "",
        price: 0,
        priceCommission: 0,
        priceDiscountPercent: 0,
        totalPrice: 0,
      })
    }
    // TODO: Linh Vu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const updateDraftMutation = useMutation({ mutationFn: specialService.updateDraftService })

  const onSubmit = async (values: InsuranceDraftEditBody) => {
    const payload: InsuranceDraftEditBody = {
      ...values,
      id: InsuranceItem?.id,
    }
    await updateDraftMutation.mutateAsync({ ...payload })
    enqueueSnackbar("Đổi gói thành công")
    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    onClose()
    onSuccess?.()
  }

  const handleSelectPlan = (plan: SpecialServicingPlan) => {
    setValue("coverPrice", plan.coverPrice)
    setValue("price", plan.price)
    setValue("priceCommission", plan.priceCommission)
    setValue("priceDiscountPercent", plan.priceDiscountPercent)
    setValue("totalPrice", plan.totalPrice)
    setValue("planName", plan.name)
    setValue("planDetails", plan.details)
    setValue("planId", plan.id)
    setValue("currency", plan.currency)
    setValue("coverPriceCurrency", plan.coverPriceCurrency)
  }

  const [hideDiscount, toggleHideDiscount] = useToggle(false)

  useEffect(() => {
    toggleHideDiscount(false)
  }, [props.open, toggleHideDiscount])

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Đổi gói bảo hiểm</DialogTitle>
      <DialogContent>
        <Card className="mb-4">
          <CardHeader title="Chọn gói bảo hiểm" />
          <CardContent>
            <Stack spacing={2}>
              <Flex className="justify-between">
                <Typography variant="h6">Gói cũ:</Typography>
                <Typography variant="h6">{InsuranceItem?.planName}</Typography>
              </Flex>
              <Flex className="justify-between">
                <Typography variant="h6">Gói mới:</Typography>
                <Typography color={"yellowgreen"} variant="h6">
                  {watch("planName")}
                </Typography>
              </Flex>
            </Stack>
          </CardContent>
        </Card>

        {getPlansMutation.isPending && (
          <Box className={"flex flex-col items-center justify-center"}>
            <Typography component="div" mb={1} variant="h6">
              Đang tìm kiếm danh sách gói bảo hiểm
            </Typography>
            <CircularProgress size={24} />
          </Box>
        )}

        {plans.length > 0 && (
          <Card>
            <CardHeader
              action={
                <>
                  <Button
                    className="mr-4 px-[10px]"
                    color="warning"
                    onClick={() => toggleHideDiscount(!hideDiscount)}
                    variant="outlined"
                  >
                    {hideDiscount ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </Button>
                </>
              }
              title="Danh sách gói bảo hiểm"
            />
            <CardContent>
              <Box className="overflow-auto" display="flex" gap={2} justifyContent="safe center" mb={2}>
                {plans.map((plan, pIndex) => (
                  <Card
                    className={plan.id === watch("planId") ? "border-[green]" : ""}
                    key={pIndex + ""}
                    sx={{ minWidth: 275 }}
                  >
                    <CardContent>
                      <Box className={`flex items-center justify-between ${hideDiscount ? "mb-2" : "mb-4"}`}>
                        <Typography component="div" variant="h5">
                          Gói {plan.name}
                        </Typography>
                        <Button
                          color={plan.id === watch("planId") ? "success" : "primary"}
                          disabled={plan.id === InsuranceItem?.planId}
                          onClick={() => handleSelectPlan(plan)}
                          size="small"
                        >
                          {plan.id === InsuranceItem?.planId
                            ? "Gói cũ"
                            : plan.id === watch("planId")
                            ? "Đã chọn"
                            : "Chọn gói"}
                        </Button>
                      </Box>
                      <Flex alignItems={"end"} justifyContent={"space-between"} sx={{ mb: 0.5 }}>
                        <Typography color="black" fontWeight={"bold"}>
                          Giá:
                        </Typography>

                        <Box>
                          {hideDiscount && (
                            <>
                              <Typography className="text-right line-through" color="grey" variant="overline">
                                {formatCurrency(plan.price)} VND
                              </Typography>
                              <Typography color="black" fontWeight={"bold"} variant="h5">
                                {formatCurrency(plan.totalPrice)} VND
                              </Typography>
                            </>
                          )}
                          {!hideDiscount && (
                            <Typography color="black" fontWeight={"bold"} variant="h5">
                              {formatCurrency(plan.price)} VND
                            </Typography>
                          )}
                        </Box>
                      </Flex>

                      {hideDiscount && (
                        <Flex justifyContent={"space-between"} sx={{ mb: 0.5 }}>
                          <Typography color="black" fontWeight={"bold"}>
                            Lợi nhuận:
                          </Typography>
                          <Box>
                            <Typography className="text-green text-right" variant="body2">
                              {formatCurrency(plan.priceCommission)} VND
                            </Typography>
                          </Box>
                        </Flex>
                      )}
                      <Typography variant="body1">Tai nạn cá nhân: {formatCurrency(plan.coverPrice)} USD</Typography>
                      <Typography variant="body1">Chi phí y tế: {formatCurrency(plan.coverPrice)} USD</Typography>
                      <Typography variant="body1">
                        Trợ cứu y tế:{" "}
                        <Link className="cursor-pointer" href={INSURANCE_DOCS_LINK} target="_blank">
                          Xem chi tiết
                        </Link>
                      </Typography>
                      <Typography variant="body1">
                        Hỗ trợ du lịch:{" "}
                        <Link className="cursor-pointer" href={INSURANCE_DOCS_LINK} target="_blank">
                          Xem chi tiết
                        </Link>
                      </Typography>
                      <Typography variant="body1">Dịch vụ cứu trợ IPA: Bao gồm</Typography>

                      <CardContent className="mt-2 rounded-lg bg-[#FFE3E2]">
                        <Typography color={"#EF5350"} component="div" mb={1} variant="h5">
                          Chi phí liên quan đến Covid-19
                        </Typography>
                        <Typography color={"#EF5350"}>Đến 70 tuổi</Typography>
                        <Typography mb={1} variant="body2">
                          {[1, 2].includes(plan.id)
                            ? "Theo giới hạn, quyền lợi bảo hiểm sức khoẻ quy định tại hợp đồng/Giấy chứng nhận bảo hiểm."
                            : "Tối đa $50,000/người/Thời hạn bảo hiểm, tùy số nào thấp hơn"}
                        </Typography>
                        <Typography color={"#EF5350"}>Trên 70 tuổi</Typography>
                        <Typography variant="body2">
                          {[1, 2].includes(plan.id)
                            ? "$5,000/người/thời hạn bảo hiểm tùy số nào thấp hơn."
                            : "Tối đa $50,000/người/Thời hạn bảo hiểm, tùy số nào thấp hơn"}
                        </Typography>
                      </CardContent>
                    </CardContent>
                  </Card>
                ))}
              </Box>
              <Center>
                <Typography color={"tomato"} fontWeight={700}>
                  Lưu ý: Sản phẩm Bảo hiểm Du lịch quốc tế áp dụng cho độ tuổi từ 01 ngày tuổi đến 75 tuổi
                </Typography>
              </Center>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          Đổi gói
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCoverNoteDialog
