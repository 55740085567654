import { LoadingButton } from "@mui/lab"
import { Button, Card, CardContent, CardHeader, Container, Grid, InputAdornment, Stack, TextField } from "@mui/material"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { InputNumber } from "components"
import { Flex, Text } from "components/common"
import { AgencySelect, AirportLoungeSelect, AirportSelect } from "components/select"
import { enqueueSnackbar } from "notistack"
import { useCallback, useEffect, useMemo } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { agencyLoungeConfigService, airportService, queryClient } from "services"

type FormValues = {
  agencyId: number | string
  airportId: number | string
  airportLoungeId: number | string
  loungeFares: {
    airportLoungeFareId: number
    airportLoungeFareName: string
    price: number
  }[]
}

const AgencyLoungeConfigCreate = () => {
  const navigate = useNavigate()
  const { isTypeSystem, user } = useSelector(profileSelector)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      agencyId: "",
      airportId: "",
      airportLoungeId: "",
      loungeFares: [],
    },
  })
  const agencyId = watch("agencyId")
  const selectedAirportId = watch("airportId")
  const selectedAirportLoungeId = watch("airportLoungeId")

  const { fields: loungeFares, replace } = useFieldArray({ control, name: "loungeFares" })

  const { data: airportQuery } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => airportService.fetchAirportsAndFares({ agencyId: agencyId || undefined }),
    queryKey: ["airportService.fetchAirportsAndFares", agencyId],
  })
  const { items: airports } = airportQuery ?? {}

  const airportLounges = useMemo(() => {
    return (airports ?? []).flatMap((airport) =>
      airport.airportLounges.map((airportLounge) => ({
        ...airportLounge,
        airport,
      })),
    )
  }, [airports])

  const airportLoungeFares = useMemo(() => {
    return airportLounges.flatMap((airportLounge) =>
      airportLounge.airportLoungeFares.map((airportLoungeFare) => ({
        ...airportLoungeFare,
        airportLounge,
      })),
    )
  }, [airportLounges])

  const handleReplaceLoungeFares = useCallback(() => {
    const nextLoungeFares = airportLoungeFares
      .filter((airportLoungeFare) => airportLoungeFare.airportLounge.id === selectedAirportLoungeId)
      .map((airportLoungeFare) => ({
        airportLoungeFareId: airportLoungeFare.id,
        airportLoungeFareName: airportLoungeFare.name,
        price: airportLoungeFare.price,
      }))
    replace(nextLoungeFares)
  }, [replace, airportLoungeFares, selectedAirportLoungeId])

  useEffect(() => {
    handleReplaceLoungeFares()
  }, [handleReplaceLoungeFares])

  useEffect(() => {
    if (user && !isTypeSystem) {
      setValue("agencyId", user.agency.id)
    }
  }, [setValue, isTypeSystem, user])

  const updateAgencyLoungeConfigMutation = useMutation({
    mutationFn: agencyLoungeConfigService.createAgencyLoungeConfig,
    onSuccess: () => {
      enqueueSnackbar("Cài đặt giá theo đại lý thành công")
    },
  })

  const onSubmit = async (values: FormValues) => {
    await Promise.all(
      values.loungeFares.map((loungeFare) => {
        return updateAgencyLoungeConfigMutation.mutateAsync({
          agencyId,
          airportLoungeFareId: loungeFare.airportLoungeFareId,
          price: loungeFare.price,
        })
      }),
    )
    queryClient.invalidateQueries({ queryKey: ["agencyLoungeConfigService.fetchAgencyLoungeConfigs"] })
    queryClient.invalidateQueries({ queryKey: ["airportService.fetchAirportsAndFares"] })
    navigate(privateRoute.settingsAgencyLoungeConfigs.path)
  }
  return (
    <Container className="max-sm:px-0">
      <Stack gap={3}>
        <Card>
          <CardHeader title="Cài đặt giá theo đại lý"></CardHeader>
          <CardContent>
            <Grid columnSpacing={6} container rowSpacing={4}>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="airportId"
                  render={({ field: { onChange, value: airportId }, fieldState: { error } }) => (
                    <AirportSelect
                      airportId={airportId}
                      onChange={(event, airport) => {
                        onChange(airport?.id)
                        setValue("airportLoungeId", "")
                        replace([])
                      }}
                      textFieldProps={{
                        error: !!error,
                        helperText: error?.message,
                        required: true,
                      }}
                    />
                  )}
                  rules={{
                    required: "Sân bay không được để trống",
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="airportLoungeId"
                  render={({ field: { onChange, value: airportLoungeId }, fieldState: { error } }) => (
                    <AirportLoungeSelect
                      airportId={selectedAirportId}
                      airportLoungeId={airportLoungeId}
                      onChange={(event, airportLounge) => {
                        onChange(airportLounge?.id)
                        setValue("airportId", airportLounge?.airport.id ?? "", {
                          shouldValidate: !!airportLounge?.airport.id,
                        })
                        handleReplaceLoungeFares()
                      }}
                      textFieldProps={{
                        error: !!error,
                        helperText: error?.message,
                        required: true,
                      }}
                    />
                  )}
                  rules={{
                    required: "Phòng chờ không được để trống",
                  }}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Controller
                  control={control}
                  name="agencyId"
                  render={({ field: { onChange, value: agencyId }, fieldState: { error } }) => {
                    return (
                      <AgencySelect
                        agencyId={agencyId}
                        onChange={(event, agency) => onChange(agency?.id)}
                        readOnly={!isTypeSystem}
                        sx={isTypeSystem ? {} : { display: "none" }}
                        textFieldProps={{
                          error: !!error,
                          helperText: error?.message,
                          required: true,
                        }}
                      />
                    )
                  }}
                  rules={{
                    required: "Đại lý không được để trống",
                  }}
                />
              </Grid>
            </Grid>

            {!loungeFares.length && selectedAirportLoungeId && (
              <Text className="mt-6 italic text-gray-600">
                Phòng chờ không có loại vé nào. Vui lòng cài đặt loại vé!
              </Text>
            )}
            {loungeFares.length > 0 && (
              <>
                <Text className="mb-4 mt-6 text-lg font-bold">Giá tiền</Text>
                <Grid columnSpacing={6} container rowSpacing={2}>
                  {loungeFares.map((loungeFare, index) => {
                    return (
                      <Grid item key={loungeFare.id} lg={4} md={6} xs={12}>
                        <Controller
                          control={control}
                          name={`loungeFares.${index}.price`}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <TextField
                                fullWidth
                                label={loungeFare?.airportLoungeFareName}
                                required
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{
                                  inputComponent: InputNumber,
                                  startAdornment: <InputAdornment position="start">Giá: </InputAdornment>,
                                }}
                              />
                            )
                          }}
                          rules={{
                            required: "Giá tiền không được để trống",
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            )}
          </CardContent>
        </Card>

        <Flex className="justify-center gap-4 sm:gap-6">
          <Link to={privateRoute.settingsAgencyLoungeConfigs.path}>
            <Button color="inherit">Quay lại</Button>
          </Link>
          <LoadingButton className="px-12" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
            Cài đặt
          </LoadingButton>
        </Flex>
      </Stack>
    </Container>
  )
}

export default AgencyLoungeConfigCreate
