import { Container } from "@mui/material"

const Profile = () => {
  return (
    <Container>
      {/*  */}
      {/*  */}
    </Container>
  )
}

export default Profile
