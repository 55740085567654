import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { InputNumber } from "components"
import { DialogCloseButton, Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { queryClient, tourService } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  actionType: "CANCEL" | "REFUND" | "REJECT"
  booking: TourBooking
}

const TourBookingCancelActionDialog = ({ actionType, booking, onClose, onSuccess, ...props }: Props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TourBookingCancelOrRefundBody>({
    defaultValues: {
      note: "",
      totalPayment: 0,
    },
  })

  const cancelBookingMutation = useMutation({ mutationFn: tourService.cancelBooking })
  const rejectBookingMutation = useMutation({ mutationFn: tourService.rejectBooking })
  const refundBookingMutation = useMutation({ mutationFn: tourService.refundBooking })

  const onSubmit = async (values: TourBookingCancelOrRefundBody) => {
    if (Number(values.totalPayment) > booking.totalAmountPaid) {
      enqueueSnackbar("Số tiền hoàn lại phải nhỏ hơn số tiền đã cọc / thanh toán", { variant: "warning" })
      return
    }

    if (["CANCEL"].includes(actionType) || booking.createdBy) {
      await cancelBookingMutation.mutateAsync({ id: booking.bookingCode, note: values.note })
    } else if (["REFUND"].includes(actionType)) {
      await refundBookingMutation.mutateAsync({ id: booking.bookingCode, totalPayment: Number(values.totalPayment) })
    } else if (["REJECT"].includes(actionType)) {
      await rejectBookingMutation.mutateAsync({ id: booking.bookingCode, note: values.note })
    }

    enqueueSnackbar(["REJECT"].includes(actionType) ? `Từ chối yêu cầu huỷ tour thành công` : `Huỷ tour thành công`)
    queryClient.invalidateQueries({ queryKey: ["tourService.getBooking"] })
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{["REJECT"].includes(actionType) ? "Từ chối huỷ" : "Xác nhận huỷ tour"}</DialogTitle>
      <DialogContent>
        <Box>
          {(["CANCEL", "REJECT"].includes(actionType) || booking.createdBy) && (
            <Controller
              control={control}
              name="note"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Ghi chú"
                  multiline
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  rows={2}
                />
              )}
            />
          )}
          {["REFUND"].includes(actionType) && !booking.createdBy && (
            <>
              <Flex marginBottom={3}>
                <Typography marginRight={2}>Số tiền đã đặt cọc / thanh toán: </Typography>
                <Typography color="primary" fontWeight={700}>
                  {booking.totalAmountPaid.toLocaleString()}
                </Typography>
              </Flex>
              <Controller
                control={control}
                name="totalPayment"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Số tiền hoàn lại cho F2"
                    required
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{ inputComponent: InputNumber }}
                  />
                )}
                rules={{
                  required: "Số tiền không được để trống",
                }}
              />
            </>
          )}

          <Typography marginTop={3} variant="h6">
            {["REJECT"].includes(actionType)
              ? "Từ chối yêu cầu huỷ booking này? "
              : "Bạn có chắc chắn muốn hủy Tour này không?"}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Đóng
        </Button>
        <LoadingButton color="error" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="outlined">
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TourBookingCancelActionDialog
